@import './colors.scss';
@import './fonts.scss';
@import './spacing.scss';
@import './sizes.scss';

$maxPrimaryBtnWidth: 370px;

@media screen and (max-width: $small) {
    $maxPrimaryBtnWidth: 364px;
}

@media screen and (max-width: $xsmall) {
    $maxPrimaryBtnWidth: 295px;
}

@media screen and (max-width: $xxsmall) {
    $maxPrimaryBtnWidth: 255px;
}

@function spacing($size, $pxString: true) {
    $space: 5 * $size;

    @if $pxString ==false {
        @return $space;
    }

    @return $space +px;
}

%H2BlackLeft {
    @extend .H2BlackLeft;

    @media screen and (max-width: $xsmall) {
        font-size: 20px;
    }
}

.blockWidth {
    width: calc(100vw - 64px);
    max-width: 340px;

    @media screen and (max-width: $small) {
        width: calc(100vw - 20px);
    }

    @media screen and (max-width: $xsmall) {
        max-width: 300px;
    }

    @media screen and (max-width: $xxsmall) {
        max-width: 270px;
    }
}

.noGutter {
    margin: 0;
    padding: 0;
}

.topGutter {
    @extend .noGutter;

    padding-top: 32px;
}

.linkCursor {
    cursor: pointer;
}

.link {
    color: var(--auto-purple);

    &:hover {
        text-decoration: none;
    }
}

.iconsXbolus {
    .Stroke-5 {
        width: 11px;
        height: 15px;
        padding: 2px 1px 12px 2px;
        border: solid 3px var(--black);
    }
}

.iconXBolusWhite {
    .Stroke-5 {
        width: 11px;
        height: 15px;
        padding: 2px 1px 12px 2px;
        border: solid 3px var(--white);
    }
}

.insightBadgeClock {
    width: 35px;
    height: 35px;
    padding: 7px;
    border-radius: 10px;
    background-color: #f0f0f0;
}

.insightBadgeBolus {
    width: 35px;
    height: 35px;
    padding: 6px 10px 7px;
    border-radius: 10px;
    background-color: #f0f0f0;
}

.clearBackground {
    background-color: transparent;
}

.dayBoxBlank {
    @extend .ParagraphBlackCenter;
    display: inline-block;
    max-width: 35px;
    min-width: 35px;
    max-height: 35px;
    min-height: 35px;
    padding: 6px 0 0 0;
    border-radius: 4px;
    background-color: transparent;
    font-weight: bold;
    font-size: 15px;

    @media screen and (max-width: $xsmall) {
        font-size: 14px;
        max-width: 32px;
        min-width: 32px;
        max-height: 32px;
        min-height: 32px;
    }

    @media screen and (max-width: $xxsmall) {
        font-size: 12px;
        max-width: 28px;
        min-width: 28px;
        max-height: 28px;
        min-height: 28px;
    }
}

.dayBoxBlankShort {
    @extend .dayBoxBlank;

    max-height: 29px;
    min-height: 29px;
    padding: 0;
    margin: 0;
    white-space: normal;
    color: var(--dark-grey);
    font-size: 8px !important;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.41px;
    display: inline-block;
    vertical-align: top;

    @media screen and (max-width: $xsmall) {
        max-height: 26px;
        min-height: 26px;
    }

    @media screen and (max-width: $xxsmall) {
        max-height: 22px;
        min-height: 22px;
        font-size: 5px;
    }
}

.dayBoxGray {
    @extend .dayBoxBlank;
    background-color: var(--light-grey);
}

.dayBoxGrayTravel {
    @extend .dayBoxGray;
    background-color: var(--auto-limited) !important;
}

.dayBoxYellow {
    @extend .dayBoxGray;
    background-color: var(--high-bg);
}

.dayBoxRed {
    @extend .dayBoxGray;
    background-color: var(--low-bg);
}

.dayBoxGreen {
    @extend .dayBoxGray;
    background-color: var(--good-bg);
}

.dayBoxGray2 {
    @extend .clearBackground;
    color: var(--light-grey) !important;
}

.dayBoxGray3 {
    @extend .clearBackground;
    color: var(--dark-grey) !important;
}

.dayBoxYellow2 {
    @extend .clearBackground;
    color: var(--high-bg) !important;
}

.dayBoxRed2 {
    @extend .clearBackground;
    color: var(--low-bg) !important;
}

.dayBoxGreen2 {
    @extend .clearBackground;
    color: var(--good-bg);
}

.btnPrimary {
    display: block;
    width: 280px;
    height: 44px;
    padding: 10px;
    margin: auto;
    border-radius: 22px;
    background-color: var(--auto-purple);

    @media screen and (max-width: $small) {
        width: 280px;
    }

    @media screen and (max-width: $xsmall) {
        width: 240px;
    }

    @media screen and (max-width: $xxsmall) {
        width: 200px;
    }

    &:hover {
        text-decoration: none;
        color: var(--white);
    }

    .default {
        @extend .ParagraphWhiteCenter;
        width: auto;
        height: 20px;
    }
}

.btnDisabled {
    background: rgb(187, 189, 205);
    cursor: not-allowed;
}

.card {
    background-color: var(--white);
    border-radius: 15px;
    padding: 20px 0;
    position: relative;

    &.elevated {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, .15);
    }
}

.cardIconArrowRight {
    margin-top: -11px;
    position: absolute;
    right: spacing(4);
    top: 50%;
}

.dayTiles {
    @extend .SubtextBlackLeft;
    margin: 0 auto 20px auto;
    padding: 0;
    white-space: nowrap;
    width: 100%;
    display: block;

    .dayTileColumn {
        flex-basis: auto;
        flex-grow: 0;
        max-width: none;

        .tile {
            margin: 0 1px;

            .travelIcon {
                width: 22px;
                height: 22px;

                @media screen and (max-width: $xxsmall) {
                    width: 18px;
                    height: 18px;
                }
            }

            &.tiny {
                @extend .Tiny-RegularDark-GreyCenter;
                color: var(--dark-grey);
                font-size: 14px;
                padding: 0;
                text-align: center;

                @media screen and (max-width: $xsmall) {
                    font-size: 13px;
                }

                @media screen and (max-width: $xxsmall) {
                    font-size: 12px;
                }
            }

            &.bold {
                font-weight: bold;
            }
        }

        &:first-child .tile {
            margin-left: 0;
        }

        &:last-child .tile {
            margin-right: 0;
        }
    }
}

.plankDelta {
    margin: 0;

    .content {
        width: calc(100vw - 30px);
        margin: 12px auto;
        padding: 3px 0;
        border-radius: 8px;
        background-color: var(--light-grey);
        text-align: center;
        white-space: nowrap;
        max-height: 25px;
        min-height: 25px;
        @extend .Small-TitleBlackCenter;
    }
}

.plankBolus {
    width: calc(100vw - 30px);
    margin: 0 auto;
    padding: 15px 10px;
    border-radius: 8px;
    background-color: var(--light-grey);
    text-align: left;
    white-space: nowrap;
    max-height: 108px;
    min-height: 108px;

    .title {
        @extend .H3BlackLeft;
        text-align: left;
        display: block;
        padding: 0 8px 12px 8px;
    }

    %ovalBase {
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        border-radius: 13px;
    }

    .ovalBolus {
        @extend %ovalBase;
        padding: 5px;
        background-color: var(--black);
    }

    .ovalL {
        @extend %ovalBase;
        padding: 8px 6px 8px 7px;
        background-color: #f75e4c;
    }

    .ovalH {
        @extend %ovalBase;
        padding: 8px 7px 8px 6px;
        background-color: #ffa700;
    }

    .body {
        @extend .ParagraphBlackLeft;
        text-align: left;
        display: block;
        padding: 0 8px;
        white-space: nowrap;

        .detail {
            display: inline-block;
            font-size: 14px;

            @media screen and (max-width: $small) {
                font-size: 13px;
            }

            @media screen and (max-width: $xsmall) {
                font-size: 10.5px;
            }

            @media screen and (max-width: $xxsmall) {
                font-size: 9px;
            }
        }

        .detailLeft {
            @extend .detail;
            width: 182px;

            @media screen and (max-width: $small) {
                width: 168px;
            }

            @media screen and (max-width: $xsmall) {
                width: 136px;
            }

            @media screen and (max-width: $xxsmall) {
                width: 112px;
            }
        }
    }
}

.plankEvent {
    @extend .plankBolus;
}

.eventDetail {
    @extend %fontSizeTransition;

    .whiteBackground {
        margin: 0 auto;
        padding: 0;
        border-radius: 15px;
        background-color: var(--white);
        text-align: center;

        .summary {
            padding: 0 10px;
            background-color: inherit;

            .header {
                display: block;
                white-space: nowrap;
                text-align: left;
                padding: 0 0 0 2px;
                margin: 0 0 2px 10px;
                @extend .H1BlackLeft;
            }

            .timeRange {
                display: block;
                white-space: nowrap;
                text-align: left;
                padding: 0 0 0 2px;
                margin: 0 0 2px 10px;
                @extend .H2Dark-GreyLeft;
            }

            .dateRange {
                display: block;
                white-space: nowrap;
                text-align: left;
                padding: 0 0 6px 2px;
                margin: 0 0 0 10px;
                @extend .BodyBlackLeft;
            }
        }

        .reading {
            display: block;
            text-align: left;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            background-color: inherit;
            height: auto;

            .row {
                padding: 0 0 0 22px;
                display: block;
                line-height: 20px;

                .label {
                    @extend .H3BlackLeft;
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    text-align: left;
                    white-space: nowrap;
                }

                .value {
                    @extend .H3BlackLeft;
                    display: inline-block;
                    text-align: left;
                    white-space: nowrap;
                    margin: 0 6px 0 8px;
                    padding: 0;
                }

                .unit {
                    @extend .ParagraphDark-GreyLeft;
                    display: inline-block;
                    text-align: left;
                    white-space: nowrap;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

%fontSizeTransition {
    transition: font-size 12s;
    -moz-transition: font-size 12s;
    /* Firefox 4 */
    -webkit-transition: font-size 12s;
    /* Safari and Chrome */
    -o-transition: font-size 12s;
}

.report {
    @extend %fontSizeTransition;
    border-bottom: solid 1px var(--grey);
    background-color: inherit;

    .header {
        .patientName {
            @extend .SubtextBlackCenter;
            height: 24px;
            color: var(--black);
            text-align: left;
            padding: 16px 14px 26px 20px;
            white-space: nowrap;
        }

        .patientNameAlt {
            @extend .patientName;
            height: 16px;
            padding-top: 6px;
        }

        .loyaltyTitle {
            @extend %H2BlackLeft;
            padding: 0 0 0 20px;
            white-space: nowrap;
            text-align: left;
        }

        .dateRangeShort {
            height: 30px !important;
        }

        .dateRange {
            display: block;
            padding: 0 20px 12px 20px;
            height: 40px;

            .caption {
                @extend .H1Dark-GreyLeft;
                display: inline-flex;
                text-align: left;
                float: left;
                margin: 0;
                padding: 0;
                white-space: nowrap;

                @media screen and (max-width: $small) {
                    font-size: 20px;
                }
            }
        }
    }
}

.dashboardInsightsOn {
    margin-bottom: 160px;
}

.dashboardInsightsOff {
    width: 300px;
    margin: 30px auto 160px auto;
    object-fit: contain;
    overflow: auto;

    .icon {
        width: 300px;
        height: 180px;
    }

    .title {
        margin: 30px auto;
        font-family: IBMPlexSans, sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.71px;
        text-align: center;
        color: var(--black);
    }

    .body {
        margin: 30px auto;
        font-family: OpenSans, sans-serif;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.36px;
        text-align: center;
        color: var(--black);
    }
}

.insightEntry {
    @extend %fontSizeTransition;

    .whiteBackground {
        margin: 0 auto;
        padding: 0;
        background-color: var(--white);
        text-align: center;

        .summary {
            padding: 0 10px;
            background-color: inherit;

            .header {
                display: block;
                white-space: nowrap;
                text-align: left;
                padding: 0 0 0 2px;
                margin: 0 0 2px 10px;
                @extend .H1BlackLeft;
            }

            .timeRange {
                padding: 0 0 6px 2px;
                margin: 0 0 0 12px;
                white-space: nowrap;
                text-align: left;
                @extend .H2Dark-GreyLeft;
            }

            .insightEntryDetail {
                @extend .SubtextBlackLeft;
                display: block;
                text-align: justify;
                margin: 12px 0 20px 16px;
                padding: 0 64px 0 0;
            }
        }

        .detail {
            display: block;
            text-align: left;
            padding: 20px 0;
            background-color: var(--light-grey);

            .banner {
                background-color: inherit;
                padding: 0 16px;

                @media screen and (min-width: 390px) {
                    padding: 0 28px;
                }

                &.timeOfDayBanner {
                    height: auto;
                    position: sticky;

                    .SubtextDark-GreyLeft {
                        @media screen and (max-width: $small) {
                            font-size: 15px;
                        }
                    }

                    .timeOfDayRangeHeading,
                    .SubtextDark-GreyLeft {
                        transition: all 50ms ease-in-out;
                    }

                    .timeOfDayRangeHeading {
                        font-size: 24px;

                        @media screen and (min-width: $xsmall) {
                            font-size: 28px;
                        }

                        @media screen and (min-width: $small) {
                            font-size: 32px;
                        }
                    }

                    &.pinned {
                        border-bottom: solid 1px var(--grey);

                        .SubtextDark-GreyLeft {
                            font-size: 15px;
                        }

                        .timeOfDayRangeHeading {
                            font-size: 18px;
                        }
                    }
                }

                .count {
                    width: 16px;
                    margin: 0 5px 0 0;
                    padding: 2px 0 0 0;
                    @extend .H1BlackLeft;
                }

                .label {
                    margin: 0 0 0 5px;
                    @extend .H1Dark-GreyLeft;
                }
            }

            .eventList {
                display: block;
                text-align: left;
                padding: 0 6px;
                background-color: var(--light-grey);

                &.timeOfDayEventList {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    +hr {
                        margin: 0 28px 20px;
                    }
                }
            }
        }
    }
}

.InsightCard {
    margin: 10px 0 20px 0;
    padding: 0;
    display: block;
    border-radius: 0;
    text-align: left;

    .whiteBackground {
        width: calc(100vw - 30px);
        margin: 10px auto 20px auto;

        @media screen and (max-width: $small) {
            width: calc(100vw - 20px);
        }

        @media screen and (max-width: $xsmall) {
            width: calc(100vw - 20px);
        }

        border-radius: 15px;
        background-color: var(--white);
        text-align: center;

        .header {
            @extend .blockWidth;

            padding: 0;
            display: block;
            white-space: nowrap;
            text-align: left;
            margin: auto;

            @media screen and (max-width: $xsmall) {
                padding: 10px 0;
            }

            @media screen and (max-width: $xxsmall) {
                padding: 10px 0;
            }

            .titleFrame {
                margin: 0 auto;
                padding: 0;

                @media screen and (max-width: $small) {
                    padding: 0;
                }

                @media screen and (max-width: $xsmall) {
                    padding: 0;
                }

                .title {
                    @extend %H2BlackLeft;
                    display: inline-block;
                    padding: 0 0 0 2px;
                    margin: 0 1px 12px 10px;
                }

                .titleAlt {
                    @extend %H2BlackLeft;
                    display: inline-block;
                    padding: 0 0 0 2px;
                    margin: 0 1px 12px 10px;
                    white-space: normal;
                }

                .text {
                    @extend .SubtextBlackLeft;
                    display: inline-block;
                    padding: 0 0 0 2px;
                    margin: 0 1px 12px 10px;
                    white-space: normal;
                    text-align: left;
                }

                .textAlt {
                    @extend .SubtextBlackLeft;
                    display: inline-block;
                    padding: 18px 0 0 2px;
                    margin: 0 1px 12px 10px;
                    white-space: normal;
                    text-align: center;
                }
            }

            .dayCount {
                margin: auto;
                margin-bottom: 10px;
                text-align: center;

                .titleAlt {
                    @extend %H2BlackLeft;
                    text-align: left;
                    padding: 0 16px;
                    margin: 0 0 6px 0;
                }

                .subTitle {
                    @extend .Small-TitleBlackLeft;
                    text-align: left;
                    color: var(--black);
                    white-space: normal;
                    padding: 0 16px;

                    @media screen and (max-width: $small) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $xsmall) {
                        font-size: 12px;
                    }
                }

                .row1 {
                    padding: 0;
                    margin: 0 auto;
                    width: 100%;
                    display: block;

                    .left {
                        text-align: left;
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 0 0;

                        .top {
                            display: block;
                            margin: 0 0 0 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 42px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                        }

                        .bottom {
                            @extend .ParagraphLeftBlack;
                            display: block;
                            white-space: nowrap;
                            margin: 0;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.36px;
                            color: var(--black);
                        }
                    }
                }

                .row2 {
                    margin: 0 auto;
                    width: 100%;
                    display: block;
                    text-align: left;

                    &.noTimezoneChange {
                        margin-bottom: 19.5px;
                        margin-top: 9.5px;
                    }

                    .left {
                        display: inline-block;
                        margin: 0;
                        padding: 0 6px 0 16px;
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 96px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.99;
                        letter-spacing: -0.96px;
                    }

                    .right {
                        display: inline-block;

                        .rightTable {
                            position: relative;
                            top: -12px;

                            .top {
                                display: block;
                                margin: 0;
                                padding: 0;
                                font-family: IBMPlexSans, sans-serif;
                                font-size: 42px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 0.88;
                                letter-spacing: -0.42px;
                                color: var(--black);
                            }

                            .bottom {
                                display: block;
                                margin: 0;
                                padding: 0;
                                font-family: OpenSans, sans-serif;
                                font-size: 18px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: -0.43px;
                                color: var(--dark-grey);
                            }
                        }
                    }
                }

                .row3 {
                    margin: 0 auto;
                    width: 100%;
                    display: block;
                    text-align: left;
                }
            }

            .timeCount {
                margin: auto;
                margin-bottom: 10px;
                text-align: center;

                .high {
                    background-color: var(--up-color);
                }

                .low {
                    background-color: var(--down-color);
                }

                .normal {
                    background-color: var(--normal-color);
                }

                .divider {
                    display: block;
                    height: 1px;
                    margin: 4px 0 0 0;
                    background-color: var(--grey);
                }

                .left {
                    text-align: left;
                    display: inline-block;
                    width: 230px;
                    margin: 8px 0;

                    @media screen and (max-width: $small) {
                        width: 220px;
                    }

                    @media screen and (max-width: $xsmall) {
                        width: 200px;
                    }

                    .top {
                        display: block;
                        margin: 0 7px 0 0;
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 42px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.9;
                        letter-spacing: -0.42px;

                        .timeInRange {
                            color: var(--hypo-protect);
                        }

                        .avgUnitsPerDay {
                            color: var(--black);
                        }

                        .timeInModeAutoPct {
                            color: var(--auto-purple);
                        }
                    }

                    .bottom {
                        @extend .ParagraphLeftBlack;
                        display: block;
                        white-space: nowrap;
                        margin: 0;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.36px;
                        color: var(--black);
                    }
                }

                .right {
                    display: inline-block;

                    .rightTable {
                        position: relative;
                        top: -2px;
                        display: block;
                        width: 45px;
                        height: 64px;
                        margin: 0;
                        padding: 7px 1px 7px 0;
                        border-radius: 8px;

                        .top {
                            display: block;
                            padding: 0;
                            margin: 0;
                            margin-left: auto;
                            margin-right: auto;
                            text-align: center;
                        }

                        .bottom {
                            display: block;
                            margin: 0;
                            margin-left: auto;
                            margin-right: auto;
                            text-align: center;
                            padding: 0;
                            font-family: OpenSans, sans-serif;
                            font-size: 18px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: -0.43px;
                            color: var(--dark-grey);
                        }
                    }
                }
            }

            .timeInRangeSummary {
                margin: auto;
                margin-bottom: 10px;
                text-align: center;

                .titleAlt {
                    @extend %H2BlackLeft;
                    text-align: left;
                    padding: 0 16px;
                    margin: 0 0 6px 0;
                }

                .subTitle {
                    @extend .Small-TitleBlackLeft;
                    text-align: left;
                    color: var(--black);
                    white-space: normal;
                    padding: 0 16px;

                    @media screen and (max-width: $small) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $xsmall) {
                        font-size: 12px;
                    }
                }

                .high {
                    background-color: var(--up-color);
                }

                .low {
                    background-color: var(--down-color);
                }

                .normal {
                    background-color: var(--normal-color);
                }

                .row1 {
                    padding: 0;
                    margin: 0 auto;
                    width: 100%;
                    display: block;

                    .left {
                        text-align: left;
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 0 0;

                        .top {
                            display: block;
                            margin: 0 0 0 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 42px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                        }

                        .bottom {
                            @extend .ParagraphLeftBlack;
                            display: block;
                            white-space: nowrap;
                            margin: 0;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.36px;
                            color: var(--black);
                        }
                    }
                }

                .row2 {
                    padding: 12px 0 12px 6px;
                    margin: 0 auto;
                    width: 100%;
                    display: block;
                    text-align: left;

                    .left {
                        text-align: left;
                        display: inline-block;
                        margin: 0 7px 0 0;
                        padding: 0 0 0 6px;
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 72px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.9;
                        letter-spacing: -0.42px;

                        @media screen and (max-width: $xsmall) {
                            font-size: 60px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            font-size: 48px;
                        }
                    }

                    .right {
                        display: inline-block;

                        .middle {
                            display: inline-block;
                            text-align: left;
                            margin: 0;
                            padding: 0;
                            font-family: OpenSans, sans-serif;
                            font-size: 18px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: -0.43px;
                            color: var(--dark-grey);
                        }
                    }
                }

                .row3 {
                    margin: 0 auto;
                    padding: 0;
                    width: 100%;
                    display: block;
                    text-align: left;

                    @media screen and (max-width: $xsmall) {
                        width: 254px;
                    }

                    @media screen and (max-width: $xxsmall) {
                        width: 214px;
                    }

                    .chart {
                        height: 30px;
                        display: block;
                    }
                }

                .row4 {
                    margin: 0 auto;
                    padding: 0;
                    width: 100%;
                    display: block;
                    text-align: left;

                    @media screen and (max-width: $xsmall) {
                        width: 254px;
                    }

                    @media screen and (max-width: $xxsmall) {
                        width: 214px;
                    }

                    .caption {
                        @extend .TinyBlackLeft;
                        display: block;
                        text-align: left;
                    }

                    .value {
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.71px;
                        text-align: left;
                        background-color: transparent;
                    }

                    .left {
                        text-align: left;
                        float: left;

                        .low {
                            color: var(--low-bg);
                            display: block;
                        }
                    }

                    .right {
                        float: right;
                        text-align: right;

                        .high {
                            color: var(--high-bg);
                            display: block;
                        }
                    }
                }
            }

            .glucoseTrends {
                margin: auto;
                margin-bottom: 10px;
                text-align: center;

                .titleAlt {
                    @extend %H2BlackLeft;
                    text-align: left;
                    padding: 0 16px;
                    margin: 0 0 6px 0;
                }

                .row1 {
                    padding: 0;
                    margin: 0 auto;
                    width: 100%;
                    display: block;

                    .left {
                        text-align: left;
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 0 0;

                        .top {
                            display: block;
                            margin: 0 0 0 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 42px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                        }

                        .bottom {
                            @extend .ParagraphLeftBlack;
                            display: block;
                            white-space: nowrap;
                            margin: 0;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.36px;
                            color: var(--black);
                        }
                    }
                }

                .row2 {
                    padding: 32px 12px 12px 12px;
                    margin: 0 auto;
                    width: 100%;
                    display: block;
                    text-align: left;

                    @media screen and (max-width: $xsmall) {
                        padding-top: 2px;
                    }

                    .left {
                        text-align: left;
                        display: inline-block;
                        margin: 0 7px 0 0;
                        padding: 0 0 0 6px;
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 72px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.9;
                        letter-spacing: -0.42px;

                        @media screen and (max-width: $xsmall) {
                            font-size: 42px;
                        }
                    }

                    .right {
                        display: inline-block;

                        .top {
                            display: block;
                            margin: 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 42px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                        }

                        .bottom {
                            display: block;
                            white-space: nowrap;
                            margin: 0 0 0 4px;
                            font-family: OpenSans, sans-serif;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: normal;
                            color: var(--dark-grey);
                            letter-spacing: -0.43px;

                            @media screen and (max-width: $xsmall) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .row3 {
                    padding: 0 0 12px 0;
                    margin: auto 24px;
                    display: block;
                    text-align: left;

                    .comparison {
                        text-align: left;
                        white-space: normal;
                        width: 295px;
                        color: rgb(0, 0, 0);
                        font-size: 18px;
                        font-family: OpenSans, sans-serif;
                        letter-spacing: -0.64px;
                        line-height: 26px;

                        @media screen and (max-width: $xsmall) {
                            font-size: 16px;
                        }

                        .delta {
                            font-weight: bold;
                            margin: 0 8px 0 0;
                        }
                    }
                }
            }

            .insulinUsage {
                margin: auto;
                margin-bottom: 10px;
                text-align: center;

                .titleAlt {
                    @extend %H2BlackLeft;
                    text-align: left;
                    padding: 0;
                    margin: 0;
                }

                .bodyAlt {
                    @extend .BodyBlackLeft;
                    text-align: left;
                    padding: 0;
                    margin: 0;
                    white-space: normal;
                    width: auto;

                    @media screen and (max-width: $xxsmall) {
                        font-size: 20px;
                    }
                }

                .subTitle {
                    @extend .Small-TitleBlackLeft;
                    text-align: left;
                    color: var(--black);

                    @media screen and (max-width: $small) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $xsmall) {
                        font-size: 12px;
                    }
                }

                .high {
                    background-color: var(--up-color);
                }

                .low {
                    background-color: var(--down-color);
                }

                .normal {
                    background-color: var(--normal-color);
                }

                .row1 {
                    display: block;

                    .left {
                        text-align: left;
                        display: inline-block;
                        width: 206px;
                        margin: 8px 0;

                        @media screen and (max-width: $small) {
                            width: 196px;
                        }

                        @media screen and (max-width: $xsmall) {
                            width: 176px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            width: 136px;
                        }

                        .top {
                            display: block;
                            margin: 0 7px 0 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 42px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                        }

                        .bottom {
                            @extend .ParagraphLeftBlack;
                            display: block;
                            white-space: nowrap;
                            margin: 0;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.36px;
                            color: var(--black);
                        }
                    }

                    .right {
                        display: inline-block;
                        position: relative;
                        top: -22px;

                        .rightTable {
                            position: relative;
                            top: 6px;
                            left: 4px;
                            display: block;
                            width: 82px;
                            height: 51px;
                            margin: 0;
                            padding: 10px 0 0 0;
                            border-radius: 8px;

                            .icon {
                                background-color: inherit;
                                display: inline-block;
                                padding: 2px 0 0 0;
                            }

                            .text {
                                text-align: left;
                                display: inline-block;
                                padding: 0 0 0 4px;
                                vertical-align: middle;
                                font-family: OpenSans, sans-serif;
                                font-size: 18px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: -0.43px;
                                color: var(--dark-grey);
                            }
                        }
                    }
                }

                .row2 {
                    padding: 0 0 12px 0;
                    width: 300px;
                    margin: auto;
                    display: block;
                    text-align: left;

                    .left {
                        text-align: left;
                        display: inline-block;
                        margin: 0 7px 0 0;
                        padding: 0 0 0 6px;
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 72px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.9;
                        letter-spacing: -0.42px;
                        color: var(--black);

                        @media screen and (max-width: $xsmall) {
                            font-size: 60px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            font-size: 32px;
                        }
                    }

                    .right {
                        display: inline-block;

                        .middle {
                            display: inline-block;
                            text-align: left;
                            margin: 0;
                            padding: 0;
                            font-family: OpenSans, sans-serif;
                            font-size: 18px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: -0.43px;
                            color: var(--dark-grey);
                            width: 300px;
                        }
                    }
                }

                .row3 {
                    margin: 0 10px;

                    .chart {
                        height: 30px;
                        display: block;
                    }
                }

                .row4 {
                    margin: auto;
                    display: block;

                    .caption {
                        @extend .TinyBlackLeft;
                        display: block;
                        text-align: left;
                    }

                    .value {
                        font-family: IBMPlexSans, sans-serif;
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.71px;
                        text-align: left;
                        background-color: transparent;

                        @media screen and (max-width: $small) {
                            font-size: 18px;
                        }

                        @media screen and (max-width: $xsmall) {
                            font-size: 17px;
                        }
                    }

                    .left {
                        width: 180px;
                        display: inline-block;
                        text-align: left;

                        @media screen and (max-width: $xsmall) {
                            width: 160px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            width: 126px;
                        }

                        .low {
                            color: var(--dark-grey);
                            display: block;
                        }
                    }

                    .right {
                        width: 100px;
                        display: inline-block;
                        text-align: right;

                        .high {
                            color: var(--dark-grey);
                            display: block;
                        }
                    }
                }
            }

            .modeUsage {
                margin: auto;
                margin-bottom: 10px;
                text-align: center;

                @media screen and (max-width: $xsmall) {
                    margin: 0 10px;
                }

                .titleAlt {
                    @extend %H2BlackLeft;
                    text-align: left;
                    padding: 0;
                    margin: 0;
                }

                .subTitle {
                    @extend .Small-TitleBlackLeft;
                    text-align: left;
                    color: var(--black);

                    @media screen and (max-width: $small) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $xsmall) {
                        font-size: 12px;

                        @media screen and (max-width: $xxsmall) {
                            white-space: normal;
                        }
                    }
                }

                .high {
                    background-color: var(--up-color);
                }

                .low {
                    background-color: var(--down-color);
                }

                .normal {
                    background-color: var(--normal-color);
                }

                .row1 {
                    display: block;

                    .left {
                        text-align: left;
                        display: inline-block;
                        width: 206px;
                        margin: 8px 0;

                        @media screen and (max-width: $xsmall) {
                            width: 176px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            width: 126px;
                        }

                        .top {
                            display: block;
                            margin: 0 7px 0 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 42px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                        }

                        .bottom {
                            @extend .ParagraphLeftBlack;
                            display: block;
                            white-space: nowrap;
                            margin: 0;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.36px;
                            color: var(--black);
                        }
                    }

                    .right {
                        display: inline-block;
                        position: relative;
                        top: -22px;

                        .rightTable {
                            position: relative;
                            top: 6px;
                            left: 4px;
                            display: block;
                            width: 82px;
                            height: 51px;
                            margin: 0;
                            padding: 10px 0 0 0;
                            border-radius: 8px;

                            .icon {
                                background-color: inherit;
                                display: inline-block;
                                padding: 2px 0 0 0;
                            }

                            .text {
                                text-align: left;
                                display: inline-block;
                                padding: 0 0 0 4px;
                                vertical-align: middle;
                                font-family: OpenSans, sans-serif;
                                font-size: 18px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: -0.43px;
                                color: var(--dark-grey);
                            }
                        }
                    }
                }

                .row2 {
                    width: 300px;
                    margin: auto;
                    display: block;
                    text-align: left;
                    padding: 0;

                    .left {
                        display: inline-block;
                        width: 160px;
                        margin-left: 12px;

                        @media screen and (max-width: $xsmall) {
                            margin-left: 6px;
                            width: 144px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            margin-left: 6px;
                            width: 139px;
                        }

                        .top {
                            text-align: left;
                            display: block;
                            margin: 8px 0;
                            margin: 0 7px 0 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 72px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                            color: var(--auto-purple);

                            @media screen and (max-width: $xsmall) {
                                font-size: 60px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                font-size: 32px;
                            }
                        }

                        .middle {
                            display: block;
                            margin: 0;
                            padding: 0 0 0 4px;
                            font-family: OpenSans, sans-serif;
                            font-size: 18px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: -0.43px;
                            color: var(--dark-grey);

                            @media screen and (max-width: $xsmall) {
                                font-size: 16px;
                            }
                        }

                        .bottom {
                            display: block;
                            padding: 12px 0 0 6px;

                            .caption {
                                @extend .TinyBlackLeft;
                                display: block;
                                text-align: left;
                            }

                            .value {
                                font-family: IBMPlexSans, sans-serif;
                                font-size: 20px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: -0.71px;
                                text-align: left;
                                background-color: transparent;
                            }

                            .left {
                                width: 80px;
                                display: inline-block;
                                text-align: left;

                                .low {
                                    color: var(--dark-grey);
                                    display: block;
                                }
                            }

                            .right {
                                width: 70px;
                                display: inline-block;
                                text-align: right;

                                .high {
                                    color: var(--manual-blue);
                                    display: block;
                                }
                            }
                        }
                    }

                    .right {
                        display: inline-block;

                        .chart {
                            position: relative;
                            top: -60px;
                            right: -10px;
                            height: 130px;
                            width: 130px;
                            display: block;
                            margin: auto;

                            @media screen and (max-width: $xsmall) {
                                font-size: 16px;
                                height: 100px;
                                width: 100px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                font-size: 16px;
                                height: 70px;
                                width: 70px;
                            }
                        }
                    }
                }
            }

            .loyaltyHeader {
                @extend .titleFrame;

                padding: 12px 0 8px 0;
            }

            .loyaltyBody {
                @extend .titleFrame;

                padding: 0px 0 8px 0;
            }

            .loyaltyFooter {
                @extend .titleFrame;

                padding: 0;
            }
        }

        .headerSummary {
            @extend .header;
        }

        .headerLoyalty {
            @extend .blockWidth;

            padding: 0;
            display: block;
            white-space: nowrap;
            text-align: center;
            margin: auto;

            .loyaltyCongrats {
                padding: 12px 0 14px 0;

                .btnPrimary {
                    max-width: $maxPrimaryBtnWidth;
                    width: 100%;

                    // Overriding widths at different BPs
                    @media screen and (max-width: $small) {
                        width: 100%;
                    }

                    @media screen and (max-width: $xsmall) {
                        width: 100%;
                    }

                    @media screen and (max-width: $xxsmall) {
                        width: 100%;
                    }
                }

                .loyaltyRow {
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 0;
                    margin: 0 0 8px 14px;

                    @media screen and (max-width: $xsmall) {
                        margin: 0 0 8px 0;
                    }

                    .loyaltyLeft {
                        display: inline-block;
                        width: 160px;
                        font-size: 18px;

                        @media screen and (max-width: $xsmall) {
                            margin-left: 0px;
                            width: 124px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            margin-left: 0px;
                            width: 119px;
                        }

                        .titleAlt1 {
                            @extend %H2BlackLeft;
                            text-align: left;
                            padding: 0;
                            margin: 0 0 11px 0;
                        }

                        .titleAlt2 {
                            @extend %H2BlackLeft;
                            text-align: left;
                            letter-spacing: -0.64px;
                            padding: 0;
                            margin: 0;
                            font-weight: unset;
                            @extend .BodyBlackLeft;
                        }

                        .titleAlt3 {
                            @extend %H2BlackLeft;
                            text-align: left;
                            padding: 0;
                            font-size: 52px;
                            font-weight: bold;
                            letter-spacing: -1.4px;
                            margin: -9px 0px -8px 0px;

                            @media screen and (max-width: $xxsmall) {
                                font-size: 30px;
                            }
                        }

                        .titleAlt4 {
                            @extend .titleAlt3;

                            font-size: 34px;
                            font-weight: bold;

                            @media screen and (max-width: $xxsmall) {
                                font-size: 20px;
                            }
                        }
                    }

                    .loyaltyRight {
                        height: 0;
                        float: right;
                        padding: 49px 0 0 0;
                        margin: 0 26px 0 0;

                        @media screen and (max-width: $small) {
                            margin: 0 15px 0 0;
                        }


                        @media screen and (max-width: $xsmall) {
                            margin: 0 -18px 0 0;
                        }

                        @media screen and (max-width: $xxsmall) {
                            margin: 18px -18px 0 0;
                        }

                        .congratsLogo {
                            width: 88px;
                            height: 88px;

                            @media screen and (max-width: $small) {
                                width: 82px;
                                height: 82px;
                            }

                            @media screen and (max-width: $xsmall) {
                                width: 70px;
                                height: 70px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                width: 61px;
                                height: 61px;
                            }
                        }
                    }

                    .loyaltyBlock {
                        @extend .titleAlt;
                        @extend .BodyBlackLeft;

                        margin: 18px auto;
                        white-space: normal;
                        display: block;
                        font-weight: unset;
                    }
                }
            }

            .loyaltyInsulinUsage {
                padding: 0;

                .loyaltyValueUnit {
                    padding-left: 4px;
                }

                .loyaltyRow1 {
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    text-align: left;
                    padding: 0;

                    .loyaltyLeft {
                        width: 100%;
                        margin-left: 12px;

                        @media screen and (max-width: $xsmall) {
                            margin-left: 0px;
                            width: 124px;
                        }

                        @media screen and (max-width: $xxsmall) {
                            margin-left: 0px;
                            width: 119px;
                        }

                        .bolusPct {
                            text-align: left;
                            display: block;
                            margin: 0;
                            padding: 0;
                            font-family: IBMPlexSans, sans-serif;
                            font-size: 72px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.9;
                            letter-spacing: -0.42px;
                            color: var(--bolus);

                            @media screen and (max-width: $xsmall) {
                                font-size: 60px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                font-size: 52px;
                            }
                        }

                        .bolusLabel {
                            display: block;
                            margin: 0;
                            padding: 0 0 6px 4px;
                            font-family: OpenSans, sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: -0.38px;
                            color: var(--black);

                            .goal {
                                margin: 0 8px;
                                font-size: 12px;
                                letter-spacing: -0.29px;
                                color: var(--goal);
                            }

                            @media screen and (max-width: $xsmall) {
                                font-size: 15px;
                            }
                        }

                        .bolusLabelAlt {
                            @extend .bolusLabel;

                            padding: 0;
                        }

                        .bolusUnits {
                            @extend .bolusLabel;

                            font-size: 14px;
                            padding: 0 0 6px 0px;
                            color: var(--dark-grey);

                            @media screen and (max-width: $xsmall) {
                                font-size: 13px;
                            }
                        }

                        .basalPct {
                            @extend .bolusPct;

                            margin: 10px 0 2px 0;
                            padding: 0 0 0 4px;
                            font-size: 24px;
                            color: var(--basal);

                            @media screen and (max-width: $xsmall) {
                                font-size: 22px;
                            }
                        }

                        .basalPctAlt {
                            @extend .basalPct;

                            padding: 0;
                        }

                        .basalLabel {
                            @extend .bolusLabel;
                        }

                        .basalLabelAlt {
                            @extend .bolusLabelAlt;
                        }

                        .basalUnits {
                            @extend .bolusUnits;
                        }
                    }

                    .loyaltyRight {
                        .loyaltyChart {
                            height: 150px;
                            width: 150px;
                            display: block;
                            margin: 0px auto;
                            position: relative;
                            top: 20px;

                            @media screen and (max-width: $xsmall) {
                                font-size: 16px;
                                height: 100px;
                                width: 100px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                font-size: 16px;
                                height: 70px;
                                width: 70px;
                            }
                        }

                        .loyaltyChartAlt {
                            @extend .loyaltyChart;

                            height: 130px;
                            width: 130px;

                            @media screen and (max-width: $small) {
                                height: 100px;
                                width: 100px;
                            }

                            @media screen and (max-width: $xsmall) {
                                height: 80px;
                                width: 80px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                height: 60px;
                                width: 60px;
                            }
                        }
                    }
                }

                .loyaltyRow2 {
                    @extend .loyaltyRow1;

                    .loyaltyLeft {
                        .avgDailyBolus {
                            @extend .bolusPct;

                            display: inline-block;
                            color: var(--black);
                        }

                        .avgDailyBolusLabel {
                            @extend .bolusLabel;

                            display: inline-block;
                            margin: 0;
                            padding: 24px 0 6px 4px;
                            font-size: 14px;
                            font-weight: 600;
                            color: var(--dark-grey);
                            vertical-align: bottom;
                            width: 102px;

                            @media screen and (max-width: $xsmall) {
                                padding-top: 6px;
                            }

                            @media screen and (max-width: $xsmall) {
                                padding-top: 6px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                padding-top: 6px;
                            }

                            .inner {
                                display: block;
                            }
                        }
                    }

                    .loyaltyBottomAlt {
                        display: block;
                        padding-top: 20px;

                        .loyaltyRow {
                            width: 100%;
                            display: block;
                            text-align: center;

                            .loyaltyColLeft {
                                max-width: 108px;
                                min-width: 108px;
                                padding: 0 66px 0 0;

                                @media screen and (max-width: $small) {
                                    max-width: 98px;
                                    min-width: 98px;
                                    padding: 0 56px 0 0;
                                }

                                @media screen and (max-width: $xsmall) {
                                    max-width: 78px;
                                    min-width: 78px;
                                    padding: 0 36px 0 0;
                                }

                                @media screen and (max-width: $xxsmall) {
                                    max-width: 74px;
                                    min-width: 74px;
                                    padding: 0 32px 0 0;
                                }
                            }

                            .loyaltyColOther {
                                max-width: 72px;
                                min-width: 72px;

                                @media screen and (max-width: $xsmall) {
                                    max-width: 56px;
                                    min-width: 56px;
                                }

                                @media screen and (max-width: $xxsmall) {
                                    max-width: 44px;
                                    min-width: 44px;
                                }
                            }

                            .loyaltyCell {
                                display: inline-block;
                                width: 25%;
                                text-align: center;

                                .loyaltyLabel {
                                    @extend .TinyBlackCenter;

                                    font-weight: normal;

                                    @media screen and (max-width: $xsmall) {
                                        font-size: 11px;
                                    }

                                    @media screen and (max-width: $xxsmall) {
                                        font-size: 10px;
                                    }
                                }

                                .loyaltyLabelTop {
                                    @extend .loyaltyLabel;

                                    font-weight: bold;
                                    display: block;
                                    padding: 8px 0 2px 0;
                                }

                                .loyaltyLabelBottom {
                                    @extend .loyaltyLabel;

                                    display: block;
                                    padding: 0 0 8px 0;
                                }

                                .loyaltyLabelLeft {
                                    @extend .loyaltyLabel;

                                    display: inline-block;
                                    font-size: 12px;
                                    max-width: 80px;
                                    min-width: 80px;
                                    white-space: normal;
                                    text-align: left;
                                    vertical-align: middle;
                                    padding-bottom: 12px;
                                }

                                .loyaltyLabelOther {
                                    @extend .loyaltyLabelLeft;

                                    text-align: center;
                                    max-width: 60px;
                                    min-width: 60px;
                                    font-weight: bold;

                                    @media screen and (max-width: $xxsmall) {
                                        max-width: 44px;
                                        min-width: 44px;
                                    }
                                }

                                .loyaltyLabelOtherTop {
                                    @extend .loyaltyLabelOther;

                                    vertical-align: top;
                                    padding-bottom: 0;
                                    font-weight: normal;
                                }

                                .loyaltyValue {
                                    @extend .H2BlackCenter;
                                }

                                .loyaltyValueNull {
                                    @extend .loyaltyValue;

                                    color: var(--null);
                                }

                                .loyaltyValueSmall {
                                    @extend .loyaltyValue;

                                    font-size: 12px;
                                }

                                .loyaltyValueNullSmall {
                                    @extend .loyaltyValueNull;

                                    font-size: 12px;
                                }
                            }

                            .loyaltyChart {
                                height: 200px;
                                display: block;
                                text-align: right;
                                margin: 0 12px 0 0;
                            }
                        }
                    }
                }

                .loyaltyRow3 {
                    border-top: solid 1px var(--black-dimmed);
                    display: block;
                    margin: 8px auto;
                    max-width: $maxPrimaryBtnWidth;
                    padding: 0;
                    text-align: left;
                    width: 100%;

                    // Overriding widths at different BPs
                    @media screen and (max-width: $small) {
                        width: 100%;
                    }

                    @media screen and (max-width: $xsmall) {
                        width: 100%;
                    }

                    @media screen and (max-width: $xxsmall) {
                        width: 100%;
                    }

                    .top {
                        display: block;

                        .cell {
                            display: inline-block;
                            width: 50%;
                            padding: 18px 0 0 0;
                            font-size: 12px;
                            font-weight: bold;
                            letter-spacing: 0px;
                            line-height: 16px;
                            color: var(--dark-grey);

                            @media screen and (max-width: $small) {
                                font-size: 10.5px;
                            }

                            @media screen and (max-width: $xsmall) {
                                font-size: 9px;
                            }

                            @media screen and (max-width: $xxsmall) {
                                font-size: 7.5px;
                            }
                        }
                    }

                    .bottom {
                        display: block;

                        .cell {
                            display: inline-block;
                            width: 50%;
                            margin: 0;
                            padding: 2px 0 8px 0px;
                            font-size: 20px;
                            font-weight: bold;
                            letter-spacing: -0.71px;
                            color: var(--black);
                        }
                    }
                }

                .loyaltyInsulinUsageRow {
                    padding: 0 20px;

                    .btnPrimary {
                        max-width: $maxPrimaryBtnWidth;
                        width: 100%;

                        // Overriding widths at different BPs
                        @media screen and (max-width: $small) {
                            width: 100%;
                        }

                        @media screen and (max-width: $xsmall) {
                            width: 100%;
                        }

                        @media screen and (max-width: $xxsmall) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .headerLoyaltyAlt {
            @extend .headerLoyalty;
            padding-bottom: 10px;
            max-width: none;
            width: calc(100vw - 30px);

            @media screen and (max-width: $small) {
                width: calc(100vw - 20px);
            }

            @media screen and (max-width: $xsmall) {
                width: calc(100vw - 20px);
            }
        }

        .timeRange {
            padding: 0 0 0 2px;
            margin: 0 0 0 12px;
            white-space: nowrap;
            text-align: left;
            @extend .H2Dark-GreyLeft;
        }

        .insightEntryDetail {
            @extend .SubtextBlackLeft;
            display: block;
            margin: 6px auto 20px auto;
            padding: 0 12px;
            text-align: left;
            max-width: 340px;

            @media screen and (max-width: $xsmall) {
                max-width: 320px;
            }

            @media screen and (max-width: $xxsmall) {
                max-width: 260px;
            }
        }

        .detail {
            display: block;
            text-align: left;
            padding: 0 0 0 18px;
            background-color: var(--light-grey);

            .count {
                width: 16px;
                margin: 0 5px 0 0;
                padding: 2px 0 0 0;
                @extend .H1BlackLeft;
            }

            .label {
                margin: 0 0 0 5px;
                @extend .H1Dark-GreyLeft;
            }
        }
    }

    .whiteBackgroundAlt {
        @extend .whiteBackground;
        padding: 10px 0;
    }
}

.insightsByPattern {
    .InsightCard {
        .whiteBackground {
            padding: 20px;

            @media screen and (max-width: $xxsmall) {
                padding: 20px;
            }

            @media screen and (max-width: $xsmall) {
                width: calc(100vw - 20px);
            }

            @media screen and (max-width: $small) {
                width: calc(100vw - 20px);
            }
        }

        .header {
            margin: 0;
            max-width: none;
            width: 100%;

            .subHeading {
                text-align: left;
                margin-left: 16px;
            }

            .heading {
                @extend %H2BlackLeft;
                @extend .subHeading;
            }

            .icon {
                @extend .insightBadgeClock;
                float: left;
                height: 50px;
                padding: 11px;
                width: 50px;
            }

            .titleFrame {
                margin: 0 auto;
                max-width: $maxPrimaryBtnWidth + spacing(2, false);
                padding: 0;

                &.titleContainer {
                    white-space: normal;
                }
            }
        }

        .dayTiles {
            margin: spacing(4) 0;
            padding: 0 spacing(1);
            width: 100%;

            >div {
                display: flex;
                justify-content: center;

                >div {
                    max-width: $maxPrimaryBtnWidth;
                    width: 100%;

                    @media screen and (min-width: 281px) {
                        width: auto;
                    }

                    @media screen and (min-width: 296px) {
                        width: 100%;
                    }
                }
            }
        }

        .btnPrimary {
            max-width: $maxPrimaryBtnWidth;
            width: 100%;

            // Overriding widths at different BPs
            @media screen and (max-width: $small) {
                width: 100%;
            }

            @media screen and (max-width: $xsmall) {
                width: 100%;
            }

            @media screen and (max-width: $xxsmall) {
                width: 100%;
            }
        }
    }
}

.insightSummary {
    @extend .InsightCard;
    display: block;
    color: black;
    max-height: 320px;
    min-height: 320px;

    .whiteBackground {
        max-height: 300px;
        min-height: 300px;
    }

    .dayTilesRow {
        padding: 0 18px;
    }
}

.insightInsulin {
    margin: 6px 0 0 0;

    .whiteBackground {
        margin: 0px auto;
        padding: 12px 0;
    }
}

.barChartLabelTir {
    font-size: 12px;
    font-family: IBMPlexSans, sans-serif;
    font-weight: normal;
    letter-spacing: -0.43px;
}

.barChartLabelBolus {
    @extend .barChartLabelTir;

    font-weight: bold;
}

.eventCell {
    margin: 20px 10px;
    padding: 0;
    display: block;
    border-radius: 8px;
    text-align: left;

    .link {
        cursor: pointer;
        color: inherit;

        &:hover {
            text-decoration: none;
        }

        .whiteBackground {
            display: flex;
            margin: 0 auto;
            padding: 22px 20px;
            text-align: left;
            width: calc(100vw - 30px);

            @media screen and (max-width: $small) {
                width: calc(100vw - 36px);
                max-height: 100px;
                min-height: 100px;
            }

            @media screen and (max-width: $xsmall) {
                width: calc(100vw - 36px);
                max-height: 92px;
                min-height: 92px;
            }

            .timeRange {
                @extend %H2BlackLeft;
                width: 100%;
                display: block;
                text-align: left;
                white-space: nowrap;

                .duration {
                    color: rgb(52, 52, 52) !important;
                    font-size: 18px;
                    font-family: OpenSans, sans-serif;
                    font-weight: normal;
                    letter-spacing: -0.64px;
                    margin: 0 0 0 8px;

                    @media screen and (max-width: $small) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: $xsmall) {
                        font-size: 14px;
                    }
                }
            }

            .dateRange {
                @extend .SubtextBlackLeft;
                display: block;
                text-align: left;
                white-space: nowrap;

                @media screen and (max-width: $small) {
                    font-size: 18px;
                }
            }

            .reading {
                display: block;
                text-align: left;
                white-space: nowrap;

                .label {
                    @extend .H3BlackLeft;
                    display: inline-block;
                    margin: 0 6px 0 0;
                    text-align: left;
                    white-space: nowrap;

                    @media screen and (max-width: $small) {
                        font-size: 18px;
                    }
                }

                .value {
                    @extend .H3BlackLeft;
                    display: inline-block;
                    margin-right: 6px;
                    padding: 0;
                    text-align: left;
                    white-space: nowrap;
                }

                .unit {
                    @extend .ParagraphDark-GreyLeft;
                    display: inline-block;
                    font-weight: bold;
                    text-align: left;
                    white-space: nowrap;
                }
            }
        }
    }
}

.feedback {
    background-color: var(--light-grey);
    border-radius: 15px;
    display: block;
    padding: 20px;
    text-align: left;

    .whiteBackground {
        margin: 0 auto;
        padding: 20px 0;
        border-radius: 15px;
        background-color: var(--white);
        text-align: center;

        .main {
            padding: 0;
            margin: 8px 10px 8px 0;
            display: inline-block;
            text-align: center;
            width: auto;
            @extend .ParagraphDark-GreyLeft;
        }

        .btnGroup {
            padding: 0;
            margin: 8px 10px 8px 0;
            display: inline-block;
            text-align: center;
            width: auto;
            white-space: nowrap;

            .btnSingle {
                display: inline-block;
                width: 54px;
                height: 38px;
                margin: 0 0 0 5px;
                padding: 6px 0;
                border-radius: 7px;
                color: var(--dark-grey);
                background-color: var(--grey);
                border: 1px solid var(--grey);
            }

            .btnSingleActive {
                color: var(--white);
                background-color: var(--dark-grey);
                border: 1px solid var(--dark-grey);
            }
        }

        .message {
            display: block;
            font-size: 14px;
            text-align: center;
            color: blue;
        }
    }
}

.dashboard {
    padding: 0 0 38px;
    background-color: var(--white);

    .banner {
        height: 46px;
        @extend .H1BlackLeft;
    }
}

.pageBanner {
    height: 52px;
    margin: 0 0 0;
    padding: 6px 14px 8px 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.solidBg {
    background-color: var(--light-grey);
}

.solidBgWhite {
    background-color: var(--white);
}

.insights {
    @extend .solidBg;
    position: relative;
    top: 0;
    margin: 0 10px 12px 10px;
    padding: 0;

    .header {
        padding: 4px 0;

        .line {
            width: calc(100vw - 28px);
            height: 1px;
            margin: 0 0 9px 1px;
            opacity: 0.1;
            border: solid 1px var(--black);
        }

        .lineAlt {
            @extend .line;
            width: auto;
            margin: 20px 0 8px 0;
        }

        .lineAlt2 {
            @extend .lineAlt;
            margin: 36px 0 12px 0;
        }

        .title {
            display: block;
            text-align: left;
            margin: 9px 0 12px 10px;
            @extend .H2Dark-GreyLeft;
        }
    }

    .carousel {
        padding: 0;

        .item {
            max-height: 300px;
            min-height: 300px;
            display: block;
            color: black;
        }

        .navButtons {
            margin: -34px 14px 0 14px;
            width: 36px;
            height: 36px;
            opacity: 0.4;

            @media screen and (max-width: $xxsmall) {
                margin: -90px 4px 0 4px;
            }
        }
    }

    .carouselIndicator {
        color: var(--auto-purple);
    }
}

.insightsFullWidth {
    @extend .insights;

    margin: 0 0 12px 0;
    background-color: var(--white);
}

.insightsFullWidthFirst {
    @extend .insightsFullWidth;

    margin-top: 10px;
}

.noData {
    margin: 24px 0 0 14px;
    @extend .Small-TitleDark-GreyLeft;
    text-align: left;
    font-weight: bold;
    color: maroon;
    background-color: lightyellow;
    padding: 2px 4px;
    border-radius: 4px;
}

.bloodGlucoseChart {
    padding: 0 0 10px 0;
    margin: 0;
    background-color: var(--white);

    .title {
        margin: 24px 0 0 26px;
        @extend .Small-TitleDark-GreyLeft;
        text-align: left;

        .line1 {
            display: block;
            font-size: 14px;
        }

        .line2 {
            display: block;
            font-size: 12px;
        }
    }

    .clickable {
        cursor: pointer;
    }

    .noData {
        @extend .noData;
    }
}

.basalModeChart {
    padding: 0 0 60px 0;
    margin: 0;
    background-color: var(--white);

    .title {
        margin: 0 0 0 26px;
        @extend .Small-TitleDark-GreyLeft;
        text-align: left;

        .line1 {
            display: block;
            font-size: 14px;
        }

        .line2 {
            display: block;
            font-size: 14px;
            cursor: pointer;
            color: var(--auto-purple);
        }
    }

    .noData {
        @extend .noData;
    }

    .chartLegend {
        margin: 20px auto auto auto;
        padding: 0 0 60px 0;
        width: 400px;
        border: 4px solid #f6f6f6;
        border-radius: 8px;

        @media screen and (max-width: $small) {
            width: 344px;
        }

        @media screen and (max-width: $xsmall) {
            width: 280px;
        }

        .legend {
            border: 1px solid transparent;
            background-color: white;
            border-radius: 4px;
            width: 100%;
            padding: 8px 12px;

            @media screen and (max-width: $xsmall) {
                padding: 8px 4px;
            }

            .header {
                text-align: left;
                color: rgb(0, 0, 0);
                font-size: 28px;
                font-family: IBMPlexSans, sans-serif;
                font-weight: bold;
                padding: 0 0 6px 8px;
            }

            .body {
                font-size: 12px;
                padding: 8px;
                text-align: left;
                background-color: #f6f6f6;
                border-radius: 8px;
                display: block;

                @media screen and (max-width: $xsmall) {
                    padding: 8px 4px;
                }

                .row {
                    display: block;
                    height: 24px;
                    margin: 8px 0;
                    white-space: nowrap;

                    .left {
                        display: inline-block;
                        width: 54px;
                        text-align: left;
                        padding-left: 10px;

                        .icon {
                            width: 24px;
                            height: 24px;
                            display: inline-block;
                            position: relative;

                            @media screen and (max-width: $small) {
                                top: 1px;
                            }

                            @media screen and (max-width: $xsmall) {
                                top: 2px;
                            }
                        }
                    }

                    .right {
                        display: inline-block;
                        white-space: nowrap;
                        text-align: left;
                        color: rgb(0, 0, 0);
                        font-size: 18px;
                        font-family: OpenSans, sans-serif;
                        font-weight: 600;

                        @media screen and (max-width: $small) {
                            font-size: 15px;
                        }

                        @media screen and (max-width: $xsmall) {
                            font-size: 12px;
                        }

                        .icon {
                            position: relative;
                            top: -5px;
                        }
                    }
                }
            }
        }
    }
}

.bolusIcon {
    z-index: 9999;
}

.chartPoint {
    z-index: 8888;
}

.chartTooltipBolus {
    max-height: 256px;
    max-width: 290px;
    min-width: 290px;
    overflow-x: auto;
    overflow-y: auto;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: var(--black);
    text-align: left;
    padding: 12px 16px;
    border-radius: 4px;
    border: 0 solid silver;
    white-space: nowrap;

    .separator {
        margin: 20px 0 16px 0;
        border-top: 1px solid var(--light-grey);
    }

    .item {
        @extend .Small-TitleWhiteLeft;
        margin: 0;
        white-space: nowrap;
        display: block;
        height: 24px;

        .left {
            float: left;
            text-align: left;
            white-space: nowrap;
            color: var(--white);
        }

        .right {
            float: right;
            text-align: right;
            white-space: nowrap;
            color: var(--disabled);
        }

        .time {
            @extend .Small-TitleLight-GreyLeft;
            color: var(--disabled);
        }
    }

    .totals {
        @extend .item;
        @extend .BodyWhiteLeft;
        margin: 4px 0 0 0;
        border-top: 1px solid var(--dark-grey);
        padding: 6px 0 0 0;
        font-size: 18px;

        .right {
            color: var(--white);
        }
    }
}

.chartTooltipInsulin {
    max-height: 256px;
    max-width: 270px;
    min-width: 270px;
    overflow-x: auto;
    overflow-y: auto;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: var(--black);
    text-align: left;
    padding: 12px 16px;
    border-radius: 4px;
    border: 0 solid silver;
    white-space: nowrap;

    .item {
        @extend .Small-TitleWhiteLeft;
        margin: 0;
        white-space: nowrap;
        display: block;
        height: 24px;
        color: var(--white);
        text-align: left;

        .left {
            text-align: left;
            white-space: nowrap;

            .dot {
                margin: 0 8px 0 0;
                display: inline-block;
                min-width: 12px;
                max-width: 12px;
                min-height: 12px;
                max-height: 12px;
                border-radius: 12px;
            }

            .icon {
                margin: 0 4px 0 0;
                width: 12px;
                height: 12px;
            }
        }

        .right {
            text-align: left;
            white-space: nowrap;
            display: inline-block;
        }
    }
}

.chartTooltip {
    background-color: #f8f8f8;
    color: black;
    text-align: justify;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid silver;
    white-space: nowrap;

    .point {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 4px;
        margin: 4px;
        position: relative;
        top: 5px;
    }

    .tag {
        margin: 6px 0 0 0;
        border-top: 1px solid gray;
        text-align: right;
        text-transform: capitalize;
    }
}

.SignIn {
    @extend .H2BlackLeft;
    text-align: left;
    width: 345px;
    height: 32px;
    margin: 30px 15px 5px 0;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--black);
}

.SignIntoYourPump {
    @extend .BodyBlackLeft;
    text-align: left;
    width: 238px;
    height: 24px;
    margin: 5px 122px 40px 0;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--black);
}

.InputText {
    @extend .ParagraphDark-GreyLeft;
    width: 286px;
    height: 56px;
    padding: 16px 16px 16px 16px;
    margin: 26px 0 8px 0;
    border-radius: 3.5px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--dark-grey);
    background-color: var(--white);
    display: block;
}

.ForgotOmnipodIdPo {
    @extend .BodyBlackLeft;
    width: 345px;
    height: 48px;
    margin: 30px 6px 0 0;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--auto-purple);
    font-weight: 600;
    text-align: left;

    .textStyle1 {
        color: var(--black);

        a {
            color: var(--auto-purple);
        }
    }
}

.OmnipodLogo {
    width: 160px;
    height: 100px;
    margin: 60px auto 15px;
    padding: 4px 8px 4px 9px;
    object-fit: contain;
}

.errorPage {
    width: 300px;
    margin: 30px auto 160px auto;
    object-fit: contain;
    overflow: auto;

    .title {
        margin: 30px auto;
        height: 100px;
        font-size: 28px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -1px;
        color: var(--black);
    }
}

%popupSubCommon {
    margin: auto;
    text-align: center;
    background-color: var(--white);
    padding: 20px 0 0 0;
    width: 320px;
    height: auto;
    overflow: auto;

    @media screen and (max-width: $xsmall) {
        width: 280px;
    }

    @media screen and (max-width: $xxsmall) {
        width: 250px;
    }
}

%popupCommon {
    @extend %popupSubCommon;
    border-radius: 10px;
    position: absolute;
    top: 84px;
    max-height: calc(100vh - 100px);
    left: calc(100vw / 2 - 160px);

    @media screen and (max-width: $xsmall) {
        left: calc(100vw / 2 - 140px);
    }

    @media screen and (max-width: $xxsmall) {
        left: calc(100vw / 2 - 125px);
    }
}

%commonBackdrop {
    display: block;
    width: calc(100vw);
    height: calc(100vh);
    position: absolute;
    top: 0;
    left: 0;
}

%commonBackdrop1Alert {
    @extend %commonBackdrop;
    top: 29px;
    height: calc(100vh - 29px);
}

%commonBackdrop2Alert {
    @extend %commonBackdrop;
    top: 58px;
    height: calc(100vh - 58px);
}

.checkBoxContainer {
    text-align: left;
    margin: 18px 0 6px;

    .checkBoxLabel {
        font-size: 14px;
        vertical-align: top;
        white-space: normal;
        font-weight: bold;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.modalBackdrop {
    @extend %commonBackdrop;
    background-color: var(--white);
}

.modalBackdropAlt1 {
    @extend .modalBackdrop;
    @extend %commonBackdrop1Alert;
}

.modalBackdropAlt2 {
    @extend .modalBackdrop;
    @extend %commonBackdrop2Alert;
}

.modalBackdropTranslucent {
    @extend %commonBackdrop;
    background-color: gainsboro;
    opacity: 0.85;
}

.modalBackdropTranslucentAlt1 {
    @extend .modalBackdropTranslucent;
    @extend %commonBackdrop1Alert;
}

.modalBackdropTranslucentAlt2 {
    @extend .modalBackdropTranslucent;
    @extend %commonBackdrop2Alert;
}

.welcome {
    @extend %popupCommon;

    .icon {
        width: 240px;
        height: 280px;
    }

    .title {
        margin: 30px auto;
        height: 37px;
        font-size: 28px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -1px;
        color: var(--black);
    }

    .body {
        height: 40px;
        font-size: 15px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        text-align: center;
        letter-spacing: -0.36px;
        color: var(--black);
        margin: 0 0 76px 0;
    }
}

.disclaimer {
    @extend %popupCommon;

    .title {
        height: 36px;
        font-size: 28px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -1px;
        color: var(--black);
        margin: 20px 0;
    }

    .par {
        font-size: 15px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        letter-spacing: -0.36px;
        color: var(--black);
        text-align: left;
        margin: 20px;
    }

    .parLast {
        @extend .par;
        margin: 20px 0 30px 0;
        text-align: center;
    }

    .icon {
        width: 66px;
        height: 66px;
        margin: 20px 8px 0 8px;
        object-fit: contain;
    }

    .caution {
        height: 37px;
        font-size: 28px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -1px;
        color: var(--black);
    }
}

.eulaAndConfAgr {
    text-align: center !important;
    background-color: var(--white) !important;

    .body {
        @extend %popupSubCommon;

        font-size: 15px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        text-align: left;
        letter-spacing: -0.36px;
        color: var(--black);

        .titleBlock {
            background-color: #f7f7f7;
            border-radius: 10px;
            padding: 12px 0;
            display: block;

            .titleA {
                margin: auto 20px;
                color: var(--dark-grey);

                .titleBold {
                    font-weight: bold;
                    display: inline-flex;
                }
            }

            .titleB {
                margin: 6px 20px 0;
                font-size: 24px;
                font-family: IBMPlexSans, sans-serif;
                font-weight: bold;
                letter-spacing: -0.86px;
            }
        }

        .contact {
            margin: 20px 0 0;
            text-align: left;
            color: var(--light-grey2);
        }

        .need {
            margin: 20px 0 0;
            font-size: 28px;
            font-family: IBMPlexSans, sans-serif;
            font-weight: bold;
            letter-spacing: -1px;
        }

        .needBeforeAfter {
            margin: 16px 0 12px;
            color: $colorFg;
            font-size: 16px;
            font-weight: normal;
        }

        .parLast {
            @extend %body;
            margin: 32px 0 130px 0;
            text-align: center;
        }
    }
}

.edNotes {
    @extend .ParagraphBlackLeft;
    background-color: var(--light-grey);
    font-weight: 600;
    letter-spacing: -0.36px;
    color: var(--black);
    margin: 0;
    padding: 0;
    text-align: left;

    .factorsToConsider {
        @extend .BodyBlackLeft;
        background-color: var(--white);
        padding: 12px 15px 15px 24px;
        letter-spacing: -0.43px;
        color: var(--black);
    }

    .areYou {
        background-color: var(--white);
        margin: 0;
        padding: 0 0 20px 24px;
        display: block;
        align-content: flex-end;

        .left {
            display: inline-block;
            width: 52px;
            margin: 0;
            padding: 0;
            border-radius: 4px;
            vertical-align: top;

            .icon {
                width: 40px;
                margin: 0;
                object-fit: contain;
            }
        }

        .right {
            display: inline-block;
            max-width: calc(100vw - 112px);
            vertical-align: middle;
            margin: 0;
            padding: 0;
            font-weight: 600;
            letter-spacing: -0.36px;
            color: var(--black);
        }
    }

    .warningFrame {
        background-color: var(--white);
        margin: 20px 0 40px 0;
        padding: 18px 0 0 0;
        display: block;

        .warning {
            background-color: var(--white);
            margin: 0;
            padding: 0 0 20px 24px;
            display: block;
            align-content: flex-end;

            .left {
                display: inline-block;
                width: 36px;
                margin: 0;
                padding: 0;
                border-radius: 4px;
                vertical-align: top;

                .icon {
                    width: 24px;
                    margin: 0;
                    object-fit: contain;
                }
            }

            .right {
                display: inline-block;
                max-width: calc(100vw - 96px);
                vertical-align: middle;
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 15px;
                font-family: OpenSans, sans-serif;
                letter-spacing: -0.36px;
                color: var(--black);

                .text {
                    padding: 0 12px 4px 0;
                    font-weight: 600;
                }
            }
        }
    }
}

.dashboardSummary {
    @extend .solidBg;
    position: relative;
    top: 0;
    margin: 0 10px 12px 10px;
    padding: 0;

    .header {
        padding: 4px 0;

        .line {
            width: calc(100vw - 28px);
            height: 1px;
            margin: 0 0 9px 1px;
            opacity: 0.1;
            border: solid 1px var(--black);
        }

        .title {
            display: block;
            text-align: left;
            margin: 9px 0 12px 10px;
            @extend .H2Dark-GreyLeft;
        }
    }
}

.allReports {
    .heading {
        @extend .H1BlackLeft;
        display: block;
        text-align: left;
        margin: 0;
        padding: 12px 0 12px 20px;
    }

    .noReportsYet {
        display: flex;
        justify-content: top;
        align-items: center;
        flex-direction: column;
        margin: 60px 20px;
    }

    .noReports {
        @extend .H3BlackLeft;
        margin-bottom: 14px;
    }

    .weekCell {
        margin: 20px 10px;
        padding: 0;
        display: block;
        border-radius: 8px;
        text-align: left;

        .link {
            cursor: pointer;
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }

        .whiteBackground {
            width: calc(100vw - 30px);
            margin: 0 auto;
            padding: 10px 0;
            text-align: left;
            max-height: 76px;
            min-height: 76px;

            @media screen and (max-width: $small) {
                width: calc(100vw - 36px);
                width: 340px;
            }

            @media screen and (max-width: $xsmall) {
                width: calc(100vw - 36px);
                width: 288px;
            }

            .main {
                padding: 0 0 0 20px;
                margin: 0;
                display: block;
                text-align: left;

                @media screen and (max-width: $small) {
                    padding: 0 0 0 8px;
                }

                .dateRange {
                    @extend .H3BlackLeft;
                    width: 100%;
                    display: block;
                    text-align: left;
                    white-space: nowrap;
                }

                .dateYear {
                    @extend .ParagraphDark-GreyLeft;
                    width: 100%;
                    display: block;
                    text-align: left;
                    white-space: nowrap;
                }

                .iconRightArrow {
                    width: 12px;
                    display: block;
                    text-align: right;
                    padding: 0;
                    margin: 0;
                    color: var(--black);
                    position: relative;
                    top: -35px;
                    right: 16px;
                    float: right;
                }
            }
        }
    }
}

.about {
    @extend .insightEntryDetail;
    text-align: left;

    .heading {
        @extend .H1BlackLeft;
        display: block;
        text-align: left;
        margin: 0;
        padding: 24px 0 12px 20px;
    }

    .body {
        padding: 8px;
        border-radius: 8px;
        display: block;
        margin: 8px auto 18px auto;
        width: calc(100vw - 42px);
        font-family: OpenSans, sans-serif;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.36px;
        text-align: left;
        color: var(--black);
        background-color: var(--white);

        .group {
            margin: 8px 8px 12px 8px;

            .label {
                margin: 0;
            }

            .labelAlt {
                margin: 0;
                font-family: IBMPlexSans, sans-serif;
            }

            .value {
                @extend .label;
                font-weight: normal;
                color: #808080;
            }
        }

        .groupAlt {
            @extend .group;
            margin: 16px 8px 8px 8px;
        }

        .left,
        .right {
            display: inline-block;

            .link {
                color: var(--black);
                font-weight: 600;
            }
        }

        .left {
            vertical-align: top;
        }

        .right {
            padding: 2px 0 10px 10px;
        }

        .imageRef {
            padding: 1px 0 0 0;
            width: 22px;
        }
    }
}

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;

    .image {
        margin-bottom: 0.5rem;
        font-size: 14px;
    }

    .ohNo {
        margin: 20px auto 10px;
        color: rgb(52, 52, 52);
        font-size: 24px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -0.86px;
    }

    .cantFind {
        margin: 0 auto 20px;
        max-width: 290px;
        width: calc(100vw - 40px);
        color: rgb(52, 52, 52);
        font-size: 18px;
        font-family: OpenSans, sans-serif;
        text-align: center;
        letter-spacing: -0.64px;
        line-height: 26px;

        @media screen and (max-width: $xsmall) {
            font-size: 22px;
        }

        @media screen and (max-width: $xxsmall) {
            font-size: 16px;
        }
    }
}

.enrollmentPending {
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    padding-top: 40px;

    @media screen and (max-width: $xsmall) {
        padding-top: 40px;
    }

    @media screen and (max-width: $xxsmall) {
        padding-top: 40px;
    }

    @media screen and (max-height: $xsmall) {
        padding-top: 0;
    }

    .title {
        margin: 180px auto 10px;
        height: 32px;
        color: rgb(52, 52, 52);
        font-size: 24px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -0.86px;
        max-width: 294px;

        @media screen and (max-height: $xsmall) {
            margin-top: 0;
        }
    }

    .body {
        margin: 0 auto 20px;
        max-width: 294px;
        width: calc(100vw - 40px);
        color: rgb(52, 52, 52);
        font-size: 18px;
        font-family: OpenSans, sans-serif;
        text-align: center;
        letter-spacing: -0.64px;
        line-height: 26px;

        @media screen and (max-width: $xsmall) {
            font-size: 22px;
        }

        @media screen and (max-width: $xxsmall) {
            font-size: 16px;
        }

        .labelAlt {
            margin: 0;
            font-family: IBMPlexSans, sans-serif;
        }
    }
}

.enrollmentRevoked {
    @extend .enrollmentPending;

    .title {
        margin-top: 120px;
        height: 74px;

        @media screen and (max-height: $xsmall) {
            margin-top: 0;
        }
    }
}

.help {
    .heading {
        @extend .H1BlackLeft;
        margin-left: 18px;
        margin-top: 20px;
        text-align: left;
        letter-spacing: -1px;
    }

    .whiteBackground {
        margin: 0 auto;
        padding: 16px;
        border-radius: 15px;
        background-color: var(--white);
    }

    .helpCard {
        margin: 20px;
        border-radius: 15px;
        text-align: left;
    }

    .cardTitle {
        @extend .H2Dark-GreyLeft;
        margin-bottom: 10px;
        font-size: 20px;
        letter-spacing: -0.71px;

        .labelAlt {
            margin: 0;
            font-family: IBMPlexSans, sans-serif;
        }
    }

    .linkWrapper {
        margin-bottom: 10px;
    }

    .podderText {
        margin-bottom: 10px;
        letter-spacing: -0.36px;
        font-size: 15px;
        font-weight: 600;

        @extend .H2Dark-GreyLeft;
    }
}

.download {
    .heading {
        @extend .H1BlackLeft;
        margin-left: 18px;
        margin-top: 20px;
        text-align: left;
        letter-spacing: -1px;

        @media screen and (max-width: $xsmall) {
            font-size: 22px;
        }

        @media screen and (max-width: $xxsmall) {
            font-size: 20px;
        }
    }

    .subHeading {
        font-size: 15px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        margin: 20px 18px 30px 18px;
        text-align: left;
        letter-spacing: -0.36px;

        @media screen and (max-width: $xsmall) {
            font-size: 17px;
        }

        @media screen and (max-width: $xxsmall) {
            font-size: 16px;
        }

        .daySelections {
            margin: 12px 0;

            .daySelection {
                margin: 0;
            }
        }
    }

    .footing {
        background-color: white;
        margin: 40px 8px;
        text-align: left;

        .icon {
            width: 24px;
            height: 24px;
            margin: 0px 8px;
            object-fit: contain;
        }

        .caution {
            color: rgb(0, 0, 0);
            font-size: 15px;
            font-family: OpenSans, sans-serif;
            font-weight: bold;
            letter-spacing: -0.36px;
            margin: 0px 0px 8px 0px;
        }
    }
}

.smsNumberInput {
    display: flex;

    .phoneCode {
        width: 60px;

        input {
            text-align: center;
            -webkit-text-fill-color: var(--black);
            color: var(--black);
        }

        fieldset {
            border-radius: 4px 0 0 4px;
            border-right: none;
        }
    }

    .phoneCode.disabled input {
        background-color: var(--grey);
    }

    .numberInput {
        flex: 1;
        max-width: 360px;

        input {
            color: var(--black);
        }

        fieldset {
            border-radius: 0 4px 4px 0;
        }
    }
}

.verifySmsNumber {
    text-align: center;
    background-color: var(--white);

    @extend %popupCommon;

    .title {
        font-size: 28px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: left;
        letter-spacing: -1px;
        color: var(--black);
        margin: 20px 0;
    }

    %body {
        font-size: 15px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        letter-spacing: -0.36px;
        color: var(--black);
        text-align: left;
        margin: 20px auto;
    }

    .paragraph {
        @extend %body;
    }

    .underline {
        text-decoration: underline;
    }

    .chk {
        white-space: nowrap;
        margin: 20px auto;
        text-align: left;

        input {
            vertical-align: top;
            margin: 3px 6px 0 0;
            height: 16px;
            width: 16px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: 1px solid var(--auto-purple);
            border-radius: 4px;
            outline: none;
            transition-duration: 0.3s;
            background-color: var(--white);
            cursor: pointer;
        }

        input:checked {
            background-color: var(--auto-purple);
            color: var(--white);
        }

        label {
            font-size: 16px;
            vertical-align: top;
            white-space: break-spaces;
            margin: 0;
            width: 260px;

            @media screen and (max-width: $xsmall) {
                width: 220px;
            }

            @media screen and (max-width: $xxsmall) {
                width: 190px;
            }
        }
    }

    .buttons {
        margin-bottom: 80px; // TODO: This accounts for iOs Safari's bottom URL bar. It's considered a hack and should be removed after addressing layout tech debt. 
        .button {
            font-size: 16px;
            font-family: OpenSans, sans-serif;
            font-weight: 600;
            display: block;
            max-width: 600px;
            margin: auto;
            border-radius: 60px;
            padding: 10px 80px;

            &:hover {
                text-decoration: none;
            }
        }

        .primary {
            background-color: var(--auto-purple);
            color: var(--white);
        }

        .disabled {
            background-color: var(--grey);
            color: var(--dark-grey);
            cursor: not-allowed;
        }

        .secondary {
            border: 1px solid var(--black);
            color: var(--black);
        }
    }
}

.loginCallback {
    height: -webkit-fill-available;
}

.userNotAssignedToApplicationError {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;

    img {
        width: 240px;
        height: 240px;
    }

    .title {
        margin: 48px auto 16px;
        color: $colorFg;
        font-size: 24px;
        font-family: IBMPlexSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: -0.48px;
        line-height: normal;

        @media screen and (max-width: $xxsmall) {
            font-size: 18px;
        }
    }

    .description {
        color: var(--dark-grey2);
        font-size: 18px;
        font-family: OpenSans, sans-serif;
        text-align: center;
        letter-spacing: -0.115px;
        line-height: normal;
        font-weight: normal;

        @media screen and (max-width: $xxsmall) {
            font-size: 16px;
        }
    }
    
    .link {
        color: $colorFg;
        font-weight: 600;
        margin-bottom: 32px;
    }
}