@import './colors.scss';
@import './fonts.scss';
@import './sizes.scss';

.main {
    margin: 0;
    padding: 0;

    %link {
        cursor: pointer;
        color: var(--auto-purple);
        font-weight: bold;

        &:hover {
            color: #ff0000;
        }
    }

    %yes {
        font-weight: bold;
        background-color: #9ebee7;
        color: #1184e2;
        border-color: #1184e2;
    }

    %no {
        font-weight: bold;
        background-color: #d8c2c2;
        color: #d44141;
        border-color: #d44141;
    }

    %icon {
        width: 35px;
        height: 35px;
        display: inline-block;
        padding: 3.5px;
        background-color: $colorIconBg;
        color: $colorIconFg;
        border: 1px solid $colorIconBorder;
        border-radius: 6px;
    }

    %colorCriticality {
        %common {
            background-color: transparent;
        }

        .criticalityHigh {
            @extend %common;
            color: $colorHigh;
        }

        .criticalityLow {
            @extend %common;
            color: $colorLow;
        }

        .criticalityInRange {
            @extend %common;
            color: $colorNormal;
        }
    }

    %colorCriticalityBox {
        %common {
            columns: $colorFg;
            border-radius: 6px;
            margin: 0 2px;
            padding: 4px 0 0 0;
            width: 32.72px;
            height: 32.72px;
            display: inline-block;
            text-align: center;
        }

        .criticalityHigh {
            @extend %common;
            background-color: $colorHigh;
        }

        .criticalityLow {
            @extend %common;
            background-color: $colorLow;
        }

        .criticalityInRange {
            @extend %common;
            background-color: $colorNormal;
        }
    }

    .tableGeneric {
        .table {
            display: table;
            border-collapse: separate;
            border-spacing: 3px;
        }

        .row {
            display: table-row;
        }

        .cell {
            display: table-cell;
            padding: 0;
        }
    }

    %reportFontSizeExtraLarge {
        @media screen and (max-width: $small) {
            font-size: 20pt;
        }

        @media screen and (min-width: $medium) {
            font-size: 22pt;
        }

        @media screen and (min-width: $large) {
            font-size: 24pt;
        }
    }

    %reportFontSizeLarge {
        @media screen and (max-width: $small) {
            font-size: 18pt;
        }

        @media screen and (min-width: $medium) {
            font-size: 20pt;
        }

        @media screen and (min-width: $large) {
            font-size: 22pt;
        }
    }

    %reportFontSizeMedium {
        @media screen and (max-width: $small) {
            font-size: 15pt;
        }

        @media screen and (min-width: $medium) {
            font-size: 16pt;
        }

        @media screen and (min-width: $large) {
            font-size: 19pt;
        }
    }

    %reportFontSizeSmall {
        @media screen and (max-width: $small) {
            font-size: 11pt !important;
        }

        @media screen and (min-width: $medium) {
            font-size: 12pt !important;
        }

        @media screen and (min-width: $large) {
            font-size: 13pt !important;
        }
    }

    %reportBackgroundFrameOuter {
        background-color: $colorBgShaded !important;
    }

    %reportBackgroundFrameInner {
        background-color: $colorBg !important;
    }

    %boxMargins {
        @media screen and (max-width: $small) {
            margin: 8px 4px;
        }

        @media screen and (min-width: $medium) {
            margin: 8px;
        }

        @media screen and (min-width: $large) {
            margin: 12px;
        }
    }

    %boxPaddings {
        @media screen and (max-width: $small) {
            padding: 0 6px;
        }

        @media screen and (min-width: $medium) {
            padding: 0 12px;
        }

        @media screen and (min-width: $large) {
            padding: 0 36px;
        }
    }

    %reportVerticalSpacing {
        margin: 10px 0;

        @media screen and (max-width: $small) {
            margin: 0 0;
        }

        @media screen and (min-width: $medium) {
            margin: 4px 0;
        }

        @media screen and (min-width: $large) {
            margin: 4px 0;
        }
    }

    %buttonToggle {
        background-color: ghostwhite;
        color: black;
        border: 2px solid gray;
        border-radius: 4px;
        font-family: OpenSans, 'Segoe UI', Tahoma, 'Geneva', Verdana, sans-serif;
        font-size: 9px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        left: 0;
        white-space: nowrap;
        width: 70px;
    }

    .testModeOn {
        background-color: lightgoldenrodyellow;
        text-align: left;
    }

    .qaAdvisory {
        color: maroon;
        font-size: 11px;
        font-weight: 200;
        margin: 0 6px;
        padding: 0 2px;
        text-align: left;
        font-family: monospace, sans-serif;
        white-space: nowrap;
        display: inline-block;

        .label {
            padding: 0 0 0 8px;
        }
    }

    .qaAdvisoryAlt {
        @extend .qaAdvisory;
        line-height: 12px;
        position: absolute;
        top: 0;
        left: 120px;
    }

    .qaAdvisoryBlock {
        @extend .qaAdvisory;
        display: block;
    }

    .stickToTop {
        @extend .layerTopmost;
        position: sticky;
        top: 0;
    }

    .stickToTopLevel1 {
        @extend .layerTopmostLess2;
        position: sticky;
        top: 0;
    }

    .stickToTopLevel2 {
        @extend .stickToTopLevel1;
        position: sticky;
        top: 36px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }
    }

    .stickToTopBack {
        @extend .stickToTopLevel1;
        background-color: $colorBg;
    }

    .inScroll {
        font-size: 14pt !important;
    }

    .inScroll2 {
        font-size: 12pt !important;
    }

    .dashboardZoomInScroll1OffScroll2Off {
        @extend .stickToTopLevel1;
        @extend .layerTopmostLess3;
    }

    .dashboardZoomInScroll1OnScroll2Off {
        top: 66px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }
    }

    .dashboardZoomInScroll1OnScroll2OffAlt {
        top: 92px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }
    }

    .eventsZoomInScroll1OffScroll2Off {
        @extend .stickToTopLevel1;
        @extend .layerTopmostLess3;

        top: 48px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }
    }

    .eventsZoomInScroll1OnScroll2Off {
        border-bottom: solid 1px var(--grey);
        top: 48px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }
    }

    .eventsZoomInScroll1OnScroll2OnBorderOff {
        top: 100px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }
    }

    .eventsZoomInScroll1OnScroll2OnBorderOn {
        @extend .eventsZoomInScroll1OnScroll2OnBorderOff;
        border-top: solid 1px var(--grey);
        border-bottom: solid 1px var(--grey);

        @media screen and (max-height: $height-non-stick) {
            border-top: 0;
            border-bottom: 0;
        }
    }

    .verticalSpacer {
        padding-bottom: 96px;
        display: block;
    }

    font-family: OpenSans,
    'Segoe UI',
    Tahoma,
    'Geneva',
    Verdana,
    sans-serif;
    width: 100%;
    @extend %reportBackgroundFrameOuter;
    color: $colorFg;

    .show {
        z-index: 1010;
        display: block;
        position: absolute;
        background-color: $colorBgShaded;
        opacity: 0.5;
        height: 100vh;
        width: 100vw;
        transition: visibility 0.3s;
    }

    .spinner {
        position: absolute;
        top: calc(100vh / 2 - 20px);
        left: calc(100vw / 2 - 20px);
        z-index: 1040;
        color: $colorPurple;
    }

    .spinnerSmall {
        position: relative;
        display: block;
        margin: 10px auto;
        color: $colorPurple;
        text-align: center;
    }

    .layerModal {
        z-index: 1055;
    }

    .errorPage {
        z-index: 1035;
        height: calc(100vh - 100px);
        width: calc(100vw);
        display: block;
        position: absolute;
        top: 100px;
        left: 0;
        background-color: inherit;
        overflow-y: auto;
        padding-bottom: 20px;
    }

    .errorPageTest {
        z-index: 1020; // To allow page banner and menu to show in testing
    }

    .layerTopmost {
        z-index: 1025;
    }

    .layerTopmostLess1 {
        z-index: 1020;
    }

    .layerTopmostLess2 {
        z-index: 1015;
    }

    .layerTopmostLess3 {
        z-index: 1010;
    }

    .scrollable {
        overflow: auto;
    }

    .menuFrame {
        background: white;
        color: black;
        width: 318px;
        top: 63px;
        height: calc(100vh - 63px);

        .navbarId {
            position: absolute;
            bottom: 8px;
        }
    }

    .menuFrameAlt1 {
        @extend .menuFrame;
        height: calc(100vh - 92px);
    }

    .menuFrameAlt2 {
        @extend .menuFrame;
        height: calc(100vh - 121px);
    }

    .buttonOn {
        background-color: $colorActive;
    }

    .buttonOnAlt {
        background-color: yellow !important;
    }

    .buttonOff {
        background-color: inherit;
    }

    .info {
        cursor: help;
    }

    .toUpper {
        text-transform: uppercase;
    }

    .toCapitalize {
        text-transform: capitalize;
    }

    .toLeft {
        text-align: left;
    }

    .toRight {
        text-align: right;
    }

    .padLeft {
        padding-left: 22px;
    }

    .padRight {
        padding-right: 16px;
    }

    .padTop {
        padding-top: 12px;
    }

    .padBottom {
        padding-bottom: 12px;
    }

    .hide {
        display: none;
    }

    .hideAlt {
        display: inline-block;
        width: 0;
        height: 0;
    }

    .hideAltTop {
        @extend .hideAlt;
        position: relative;
        top: -100px;
    }

    .largerText {
        font-size: 150%;
    }

    .borderTop {
        margin: 6px 0 0 0;
        border-top: 1px solid gray;
    }

    .button {
        background-color: $colorPurple;
        color: $colorFgAlt;
        border-radius: 22px;
    }

    .buttonEmpty {
        background-color: $colorBg;
        margin: 0 8px 0 0;

        .link {
            color: $colorPurple;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .bolus {
        .IconsxBolusWhite {
            width: 19px;
            height: 19px;
            object-fit: contain;
        }

        .Rectangle {
            width: 33px;
            height: 33px;
            margin: 0 1px 2px;
            padding: 7px;
            border-radius: 16.5px;
            background-color: black;
        }
    }

    .chartLabel {
        margin: 20px 0 0 0;
        font-weight: 400;
    }

    .chartLarge {
        height: 200px;
        max-width: calc(100vw - 16px);
        min-width: calc(100vw - 16px);
        display: block;
        margin: 0;

        @media screen and (max-width: $small) {
            max-width: calc(100vw - 0px);
            min-width: calc(100vw - 0px);
        }
    }

    .chartControls {
        display: block;
        padding: 0 0 4px 24px;

        .buttonBoundaries {
            @extend %buttonToggle;
            width: 86px;
        }

        .buttonLowerBolus {
            @extend %buttonToggle;
            width: 86px;
        }

        .buttonHorizontalFlip {
            @extend %buttonToggle;
            width: 72px;
        }
    }

    .chartGlucoseModeInner {
        @extend .chartLarge;
        height: 240px;
    }

    .chartGlucose {
        @extend .chartGlucoseModeInner;
        max-height: 230px;
        min-height: 230px;
        padding: 20px 0 0 0;
    }

    .chartBasalMode {
        @extend .chartGlucose;
        height: 110px;
    }

    .chartBasalModeTest {
        @extend .chartBasalMode;
        height: 340px;
    }

    .chartBasalModeInner {
        @extend .chartGlucoseModeInner;

        @media screen and (max-width: $small) {
            position: relative;
            left: -6px;
        }
    }

    .chartBasalModeInnerNew {
        @extend .chartGlucoseModeInner;
        height: 72px;

        @media screen and (max-width: $small) {
            position: relative;
            left: -6px;
        }
    }

    .windowTestFrame {
        @extend .layerModal;
        position: absolute;

        .windowTest {
            padding: 0;
            border: 1px solid gray;
            border-radius: 6px;
            background-color: lightyellow;

            .testToggle {
                position: absolute;
                right: 20px;
                top: -1px;
                display: block;
                width: 0;
                color: white;
                cursor: default;
            }

            .testHeader {
                background-color: navy;
                color: white;
                font-size: 10px;
                font-weight: bold;
                cursor: move;
                padding: 2px 6px;
                text-align: center;
                border-radius: 6px 6px 0 0;
                width: 222px;
            }

            .testBodyCollapsed {
                display: flex;
                flex-direction: column;
                padding: 2px;
                border-radius: 0 0 6px 6px;
            }

            .testBody {
                @extend .testBodyCollapsed;
                background-color: lightyellow;
                padding: 0;

                .tabs {
                    background-color: navy;
                    border-radius: 0;
                    margin: 0;
                    padding: 2px 0 0 3px;
                    max-width: 222px;
                    min-width: 222px;

                    .tabHeader {
                        color: white;
                    }
                }

                .tabPanel {
                    background-color: lightyellow;
                    border-radius: 0 0 6px 0;
                    padding: 0;
                    margin: 1px;

                    .row {
                        display: flex;
                        flex-direction: row;
                        border-width: 0 0 1px 0;
                        border-color: silver;
                        border-style: solid;
                    }

                    .buttonDefault {
                        border-width: 0 0.5px 0 0;
                        border-color: silver;
                        border-style: solid;
                        display: inline-block;
                        color: black;
                        border-radius: 0;
                        font-family: OpenSans, 'Segoe UI', Tahoma, 'Geneva', Verdana, sans-serif;
                        font-size: 9px;
                        font-weight: bold;
                        margin: 0;
                        left: 0;
                        white-space: nowrap;
                        min-width: 111px;
                        max-width: 111px;
                        text-align: left;
                        padding: 0 0 0 6px;
                        vertical-align: middle;
                        line-height: 24px;
                        cursor: pointer;

                        .switch {
                            float: right;
                        }
                    }

                    .buttonWide {
                        min-width: 222px;
                        max-width: 222px;
                    }

                    .slidercaption {
                        display: block;
                        padding: 0;
                        margin: 0;
                    }

                    .slider {
                        @extend .slidercaption;
                        min-width: 196px;
                        max-width: 196px;
                        margin: 4px 6px 10px 6px;
                    }
                }
            }
        }
    }

    .navbarBox {
        @extend %reportBackgroundFrameOuter;
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        background-color: $colorBg !important;
        margin-bottom: 0;
        height: 61px;

        .navbarBrand {
            width: calc(100vw - 0px);
            text-align: left;
            overflow: hidden;

            @media screen and (max-width: $small) {
                padding-left: 4px;
            }

            @media screen and (min-width: $medium) {
                padding-left: 8px;
            }

            @media screen and (min-width: $large) {
                padding-left: 12px;
            }
        }

        .navbarLogo {
            position: relative;
            top: 2px;
            padding: 0 8px 0 0;
            height: 36px;
            display: inline-block;

            @media screen and (max-width: $small) {
                height: 34px;
            }

            @media screen and (max-width: $xsmall) {
                height: 26px;
            }
        }

        .navbarCaption {
            position: relative;
            top: 0;
            padding: 0;
            height: 68px;
            display: inline-block;
            color: rgb(255, 167, 0);
            font-size: 22px;
            font-family: IBMPlexSans, sans-serif;
            font-weight: bold;
            letter-spacing: -0.44px;
        }

        .navbarHamburger {
            font-weight: bolder;
            font-size: 20px;
            position: absolute;
            top: 12px;
            left: calc(100vw - 76px);

            @media screen and (max-width: $xsmall) {
                left: calc(100vw - 66px);
            }

            .menu {
                width: 31px;
                height: 26px;

                .bar1,
                .bar2,
                .bar3 {
                    width: 29px;
                    height: 4px;
                    background-color: var(--auto-purple);
                    margin: 4px 0 0 0;
                    transition: 0.4s;
                    border-radius: 2px;
                }

                .bar1 {
                    margin-top: 5px;
                }

                .bar1Change {
                    width: 40px;
                    margin-top: 6px;
                    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
                    transform: rotate(-45deg) translate(-8px, 4px);
                }

                .bar2 {
                    width: 22px;
                }

                .bar2Change {
                    opacity: 0;
                }

                .bar3 {
                    width: 25px;
                }

                .bar3Change {
                    margin-top: 6px;
                    width: 40px;
                    -webkit-transform: rotate(45deg) translate(-8px, -8px);
                    transform: rotate(45deg) translate(-9px, -5px);
                }
            }
        }

        .menuItem {
            padding: 0 4px;
            border-radius: 4px;
            width: 278px;
            display: block;
            white-space: nowrap;

            .iconFrame {
                display: inline-block;
                vertical-align: top;

                .icon {
                    height: 20px;
                    margin: 0 8px 4px 16px;
                }
            }

            .labelFrame {
                display: inline-block;
                vertical-align: top;
                white-space: normal;
                width: 200px;
            }
        }

        .menuItemOn {
            background-color: $colorHltBg;
            color: $colorHltFg;
        }

        .menuItemPreSeparate {
            @extend .menuItem;
            width: 262px;
            height: 1px;
            background-color: var(--grey);
            margin: 4px 32px 4px 32px;
        }
    }

    .navbarBoxWithBannerSingle {
        @extend .navbarBox;
        top: 29px;
    }

    .navbarBoxWithBannerDouble {
        @extend .navbarBox;
        top: 58px;
    }

    .nonProdUse {
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        padding: 5px 0;
        height: 29px;
        background-color: rgba(247, 93, 76, 0.25);
        color: rgb(247, 94, 76);
        font-size: 14px;
        font-family: OpenSans, sans-serif;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0;
    }

    .unsupportedBrowserPositionFirst {
        @extend .nonProdUse;
        background-color: rgb(247, 94, 76);
        color: rgb(255, 255, 255);
    }

    .unsupportedBrowserPositionSecond {
        @extend .unsupportedBrowserPositionFirst;
        top: 29px;
    }

    .header {
        display: block;
        width: 100%;
        min-height: 62px;
        max-height: 62px;
        background-color: $colorBg;
    }

    .headerAlt1 {
        @extend .header;
        min-height: 90px;
        max-height: 90px;
    }

    .headerAlt2 {
        @extend .header;
        min-height: 118px;
        max-height: 118px;
    }

    .body {
        @extend %reportBackgroundFrameOuter;
        height: calc(100vh - 62px);
        overflow-y: auto;
        padding: 0;

        .signIn {
            text-align: center;
            display: block;
            margin: 14px auto;
            padding: 0 16px;
            width: 318px;
            align-self: center;

            .expired {
                background-color: lightyellow;
                color: red;
                font-size: 16pt;
                padding: 6px 0;
                margin: 16px 0;
                text-align: center;
                display: block;
                width: 100%;
            }

            .required {
                display: block;
                width: 100%;
                text-align: left;
                padding: 10px !important;
                color: inherit;
                font-size: 9pt;
                font-weight: bold;
            }

            .remember {
                display: block;
                width: 100%;
                text-align: left;
                padding: 0 0 0 30px;

                .checkbox {
                    background-color: $colorPurple;
                    color: white;
                }
            }

            .signInControls {
                margin: 24px 0 0 0;
            }
        }

        .signOut {
            margin: 14px 32px;

            .message {
                font-size: 16pt;
                margin: 40px 0;
                display: block;
            }
        }

        .disclaimer {
            @extend .tableGeneric;
        }

        .insightEntryDetail {
            @extend %reportBackgroundFrameInner;
            @extend %colorCriticalityBox;
            margin: 0;
            width: 100%;
            padding: 0;
            display: block;
            text-align: center;
        }
    }

    .bodyAlt1 {
        @extend .body;
        height: calc(100vh - 90px);
    }

    .bodyAlt2 {
        @extend .body;
        height: calc(100vh - 118px);
    }

    .bodyAlt3 {
        @extend .body;
        height: calc(100vh - 0px);
        background-color: var(--white) !important;
    }

    .whiteBackground {
        background-color: var(--white) !important;
    }

    .link {
        @extend %link;
    }

    .toast {
        @extend .layerModal;
        position: absolute;
        bottom: 6px;
        right: 6px;

        .banner {
            background-color: $colorBgShaded;
        }

        .title {
            color: $colorSubTitle;
            font-weight: bold;
            margin: 0 16px 0 0;
            overflow: auto;
            white-space: nowrap;
            width: 100%;
        }

        .timestamp {
            font-size: 60%;
            font-weight: bold;
            text-align: right;
            white-space: nowrap;
        }

        .message {
            font-family: OpenSans, sans-serif;
            font-size: 14px;
            white-space: pre-wrap;
            overflow: auto;
            max-height: 200px;
        }

        .messageInfo {
            background-color: $colorBg;
            color: $colorMessage;
        }

        .messageGood {
            background-color: $colorBg;
            color: $colorGood;
        }

        .messageBad {
            background-color: $colorBg;
            color: $colorBad;
        }
    }

    .infoBlock {
        @extend .BodyBlackLeft;
        max-width: 250px;
        min-width: 250px;
        font-size: 14px;
        font-weight: normal;
        padding: 12px 16px;
    }

    .rangeSelectorFrame {
        display: block;
        margin: 0;
        padding: 0;
        width: 0;
        position: absolute;
        top: 2px;
        left: calc(100vw - 20px);
    }

    .rangeSelector {
        padding: 0;
        display: inline-block;
        max-width: 0;

        .dateSelector {
            display: block;
            margin: 0;
            padding: 0;
            float: right;
            white-space: nowrap;

            .button {
                background-color: $colorPurple;
                color: $colorFgAlt;
                border-radius: 22px;
                width: 36px;
                height: 36px;
                margin: 0 0 0 2px;
                padding: 0;

                .left {
                    margin: 0 2px 0 0;
                }

                .right {
                    margin: 0 0 0 2px;
                }
            }
        }

        .weekPicker {
            margin: 0;
            padding: 0;
            display: block;
            border: 0 !important;
            position: absolute;
            top: 36px;
            right: 0;

            .icon {
                width: 42px;
                height: 42px;
                border: 0;
            }

            .pending {
                background-color: $colorPendingBg;
                color: $colorPendingFg;
                border-radius: 8px;
                border: 1px solid $colorPurple;
                padding: 2px;
            }
        }

        .datePicker {
            position: relative;
            top: 78px;
            right: 241px;
        }
    }

    .identification {
        display: block;
        min-width: 316px;
        margin: 12px 0 0 0;
        padding: 1px 1px;

        .idBanner {
            color: black;
            text-align: center;
            margin: 0;
        }

        .brief {
            font-family: IBMPlexSans, sans-serif;
            font-size: 9px;
            color: $colorFgAlt2;
            display: block;
            text-align: left;
            padding: 0 8px;

            .idName {
                display: inline-block;
                white-space: nowrap;
                width: 44px;
            }

            .idValue {
                display: inline-block;
                white-space: nowrap;
                color: navy;
                padding: 0 0 0 6px;
            }
        }

        .verbose {
            font-family: IBMPlexSans, sans-serif;
            font-size: 14px;
            color: $colorFgAlt2;
            display: block;
            text-align: left;
            padding: 0 8px;

            .idName {
                display: inline-block;
                white-space: nowrap;
                width: 176px;
            }

            .idValue {
                display: inline-block;
                white-space: nowrap;
                color: navy;
                padding: 0 0 0 6px;
            }
        }
    }

    .dataTable {
        @extend .layerTopmostLess3;
        padding: 0 0 4px 0;
        margin: 0;
        display: block;
        position: sticky;
        top: 130px;

        @media screen and (max-height: $height-non-stick) {
            top: -100vh;
        }

        .showData {
            height: 200px;
        }

        .controlMain {
            padding: 0 24px;
        }

        .buttonData {
            @extend %buttonToggle;
            width: 100px;
        }

        .buttonEgv {
            @extend .buttonData;
            width: 60px;
        }

        .buttonBolus {
            @extend .buttonData;
            width: 66px;
        }

        .buttonMode {
            @extend .buttonData;
            width: 77px;
        }

        .buttonSubMode {
            @extend .buttonData;
            width: 77px;
        }

        .buttonPod {
            @extend .buttonData;
            width: 64px;
        }

        .frame {
            border: 1px solid silver;
            display: block;
            float: left;
            margin: 0;
            padding: 0;
            max-height: 200px;
            overflow: auto;
            font-size: 8pt;
            text-align: center;
            white-space: nowrap;
            background-color: white;
            width: 100%;

            .table {
                margin: 0;
                padding: 0;
                overflow: auto;
                border: 0;

                .rowHeaderFirst {
                    background-color: inherit;
                    white-space: nowrap;
                    font-family: Calibri, sans-serif;
                    position: sticky;
                    top: 0;
                    vertical-align: bottom;

                    .cell {
                        text-align: center !important;
                        border-radius: 2px;
                        margin: 0 4px;
                        padding: 1px 4px;
                        border: 0;
                        font-family: Arial Narrow, sans-serif;
                    }

                    .cellHeader {
                        @extend .cell;
                        font-weight: bold;
                    }
                }

                .rowHeader {
                    @extend .rowHeaderFirst;
                    top: 16px;
                    border-bottom: 1px solid white;
                }

                .row {
                    white-space: nowrap;
                    border-bottom: 0.5px dotted lightgrey;
                }

                .cell {
                    padding: 0 4px;
                    text-align: right;
                    font-family: monospace, sans-serif;
                    border-left: 0.5px dotted silver;
                    border-right: 0.5px dotted silver;
                    font-size: 8pt;
                }

                .cellLeft {
                    @extend .cell;
                    text-align: left;
                }

                .smoothing {
                    color: yellow;
                }

                .odd {
                    background-color: aliceblue;
                }

                .even {
                    background-color: lightyellow;
                }

                .timestamp {
                    max-width: 138px;
                    min-width: 138px;
                }

                .reading {
                    background-color: rgb(100, 100, 100);
                    color: white;
                }

                .egv {
                    max-width: 46px;
                    min-width: 46px;
                }

                .egvWide {
                    @extend .egv;
                    max-width: 56px;
                    min-width: 56px;
                }

                .bolus {
                    background-color: rgb(0, 0, 0);
                    color: white;
                    max-width: 36px;
                    min-width: 36px;
                }

                .bolusWide {
                    @extend .bolus;
                    max-width: 66px;
                    min-width: 66px;
                }

                .mode {
                    background-color: rgb(80, 0, 0);
                    color: white;
                    max-width: 86px;
                    min-width: 86px;
                }

                .submode {
                    background-color: rgb(0, 80, 0);
                    color: white;
                    max-width: 90px;
                    min-width: 90px;
                }

                .pod {
                    background-color: rgb(0, 0, 80);
                    color: white;
                    max-width: 73px;
                    min-width: 73px;
                }
            }
        }
    }
}